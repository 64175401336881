import { createIcon } from "@chakra-ui/icons";

const Instagram = createIcon({
  displayName: "Instagram",
  viewBox: "0 0 22 22",
  path: (
    <>
      <g clip-path="url(#clip0_41_574)" fill="currentColor">
        <path d="M11 1.98c2.94 0 3.287.014 4.443.065 1.074.048 1.654.228 2.041.378.511.198.88.439 1.263.821.387.387.623.752.821 1.263.15.387.33.972.378 2.041.052 1.16.064 1.509.064 4.443 0 2.94-.012 3.287-.064 4.443-.047 1.075-.228 1.655-.378 2.041-.198.512-.438.881-.82 1.264a3.385 3.385 0 0 1-1.264.82c-.387.15-.971.331-2.041.378-1.16.052-1.508.065-4.443.065-2.94 0-3.287-.013-4.443-.064-1.074-.048-1.654-.228-2.041-.379a3.403 3.403 0 0 1-1.263-.82 3.384 3.384 0 0 1-.821-1.264c-.15-.386-.33-.97-.378-2.04-.052-1.16-.065-1.509-.065-4.444 0-2.939.013-3.287.065-4.443.047-1.074.228-1.654.378-2.04.198-.512.438-.881.82-1.264a3.383 3.383 0 0 1 1.264-.82c.387-.15.971-.331 2.041-.379 1.156-.051 1.504-.064 4.443-.064ZM11 0C8.014 0 7.64.013 6.467.064 5.298.116 4.495.305 3.798.576a5.369 5.369 0 0 0-1.95 1.272A5.39 5.39 0 0 0 .576 3.794c-.27.7-.46 1.5-.512 2.669C.013 7.64 0 8.013 0 11c0 2.986.013 3.36.064 4.533.052 1.169.241 1.973.512 2.669.283.726.657 1.34 1.272 1.95.61.61 1.224.989 1.946 1.268.7.27 1.5.46 2.669.511 1.173.052 1.546.065 4.533.065 2.986 0 3.36-.013 4.533-.065 1.169-.051 1.972-.24 2.668-.511a5.376 5.376 0 0 0 1.947-1.268c.61-.61.988-1.224 1.267-1.946.271-.7.46-1.5.512-2.668.051-1.174.064-1.547.064-4.534 0-2.986-.013-3.36-.064-4.533-.052-1.169-.241-1.972-.512-2.668a5.152 5.152 0 0 0-1.259-1.955A5.377 5.377 0 0 0 18.206.58c-.7-.27-1.5-.46-2.668-.511C14.36.013 13.986 0 11 0Z" />
        <path d="M11 5.35c-3.12 0-5.65 2.53-5.65 5.65 0 3.12 2.53 5.65 5.65 5.65 3.12 0 5.65-2.53 5.65-5.65 0-3.12-2.53-5.65-5.65-5.65Zm0 9.315a3.666 3.666 0 1 1 .001-7.331A3.666 3.666 0 0 1 11 14.665ZM18.193 5.126a1.32 1.32 0 1 1-2.639 0 1.32 1.32 0 0 1 2.639 0Z" />
      </g>
      <defs>
        <clipPath id="clip0_41_574">
          <path fill="currentColor" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export default Instagram;
