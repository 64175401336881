import { ReactComponent as Organize } from "../../images/organize.svg";
import { ReactComponent as Secure } from "../../images/secure.svg";
import { ReactComponent as Timely } from "../../images/timely.svg";
import { ReactComponent as Leave } from "../../images/leave.svg";
import { ReactComponent as Plan } from "../../images/plan.svg";
import { ReactComponent as Manage } from "../../images/manage.svg";

export const foldData = [
  {
    title: "Organize your data ",
    icon: <Organize width="100%" height="100%" />,
    content:
      "Store your information in a coherent manner to make it more organized and easily accessible.",
    direction: "row",
  },
  {
    title: "Secure your data",
    icon: <Secure width="100%" height="100%" />,
    content:
      "Protect your information using highly secure 256 bit end to end encryption, amongst other security protocols",
    direction: "row-reverse",
  },
  {
    title: "Timely check-ins",
    icon: <Timely width="100%" height="100%" />,
    content:
      "Non intrusive follow ups every 90 days to keep track of your well-being.",
    direction: "row",
  },
  {
    title: "Leave instructions",
    icon: <Leave width="100%" height="100%" />,
    content:
      "Provide guidance relating to each document to ensure your family can locate them with ease.",
    direction: "row-reverse",
  },
  {
    title: "Plan goodbyes",
    icon: <Plan width="100%" height="100%" />,
    content:
      "Leave farewell messages in case you don’t get a chance to say goodbye to your loved ones.",
    direction: "row",
  },
  {
    title: "Manage wills",
    icon: <Manage width="100%" height="100%" />,
    content:
      "Manage who has access to your last will and ensure seamless execution of your estate",
    direction: "row-reverse",
  },
];
