import { createIcon } from "@chakra-ui/icons";

const Folder = createIcon({
  displayName: "Folder",
  viewBox: "0 0 301 275",
  path: (
    <>
      <path
        d="m5.338 95.87 45.725-4.46 99.011-9.342-24.286 49.818c-5.637 13.869-13.924 33.109-23.157 52.7-16.606 35.237-36.271 71.614-49.078 79.938-19.926 12.952-28.644-15.776-30.512-31.759L.893 101.679a5 5 0 0 1 4.445-5.809Z"
        fill="#2C4E88"
        fillOpacity=".8"
        stroke="#1BEBE7"
      />
      <path
        d="m260.569 30.314-68.15-4.29-44.436 221.834 110.22-3.113 20.992-192.3c1.238-11.339-7.242-21.415-18.626-22.131Z"
        fill="#544F9F"
        fillOpacity=".6"
      />
      <path
        d="M34.62 39.255c-1.423-11.076 6.5-21.171 17.597-22.422L174.624 3.035c10.736-1.21 20.492 6.324 22.035 17.017l29.77 206.205c1.578 10.928-5.999 21.068-16.927 22.651L84.369 267.037c-11.056 1.602-21.281-6.164-22.705-17.245L34.62 39.255Z"
        fill="#3F2676"
        fillOpacity=".8"
      />
      <rect
        x="71.61"
        y="101.782"
        width="112.023"
        height="7.473"
        rx="3"
        transform="rotate(-5.332 71.61 101.782)"
        fill="#D9D9D9"
      />
      <rect
        x="66.63"
        y="80.025"
        width="112.023"
        height="7.473"
        rx="3"
        transform="rotate(-5.332 66.63 80.025)"
        fill="#D9D9D9"
      />
      <rect
        x="62.892"
        y="58.852"
        width="112.023"
        height="7.473"
        rx="3"
        transform="rotate(-5.332 62.892 58.852)"
        fill="#D9D9D9"
      />
      <path
        d="M293.331 103.798 75.452 121.609a4.981 4.981 0 0 0-4.508 4.109c-6.909 39.807-17.823 114.931-19.884 123.861-3.736 16.191-10.378 15.361-15.568 14.323 6.477 8.967 22.21 10.378 29.268 9.963l198.957-26.936a5 5 0 0 0 4.202-3.832l30.692-133.193c.76-3.297-1.907-6.381-5.28-6.106Z"
        fill="#1BEBE7"
        stroke="#1BEBE7"
      />
    </>
  ),
});

export default Folder;
