import React from "react";

import { Outlet } from "react-router-dom";

import Header from "./header";
import { Box } from "@chakra-ui/react";
import Footer from "./Footer";

const Layout = () => {
  return (
    <Box>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </Box>
  );
};

export default Layout;
