export const TermsStart = {
    title:"Terms of Service",
    content:
      [
        `This document is an electronic record in terms of Information Technology Act, 2000 and rules made thereunder (as applicable), together with any statutory amendments or modifications thereof. This electronic record is generated by a computer system and does not require any physical or digital signatures.This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement for access or usage of www.wallt.in (“Website/WALLT”).`,
        `“You” or “User” (wherever the context so requires) shall mean any natural or juridical person, who uses the Services (defined below), including but not limited to any person who has provided data, and shall include a user who has registered on WALLT.`,
        `These Terms of Service ("Terms"), together with the Privacy Policy and any other terms and conditions referenced herein, constitute a legally binding agreement ("Agreement") between you and Astaea Technologies Pvt. Ltd. (hereinafter “WALLT” “WE” or US”), governing your access to and use of WALLT, the website www.wallt.in, its contents, applications, software, products, and services (collectively, the "Services"). By accessing or using the Services, you acknowledge that you have read, understood, and agree to be bound by all the terms and conditions of this Agreement, which is, for an indefinite period. If you have any queries about the Terms or have any comments or complaints on or about WALLT, please email us at support@wallt.in.If you do not agree with any provision of this Agreement, you are advised not to obtain access to or otherwise use the Services.However, if you continue to use the Services you shall be deemed to have agreed to accept and abide by the Agreement. This Agreement shall always supersede any other understanding whether oral or written.`,
      ]
  }

export const TermsBody = [
  {
    title:"Acceptance of Terms",
    content:[
      `Eligibility: By accessing or using the Services, you affirm that you are at least 18 years old or the age of majority in your jurisdiction and have the legal capacity to enter into this Agreement.`,
      `Acceptance: Your access to and use of the Services constitutes your acceptance of this Agreement, including any modifications that may be made from time to time.`,
    ]
  },
  {
    title:"Description of Service",
    content:[
      `WALLT provides a comprehensive cloud based end-of-life planning tool that allows you to store, organize and share your data with your designated nominees and collaborators through an end-to-end encrypted platform.`,
      `You provide details of third parties designated by you as your nominees (“Nominees/Visitors") to WALLT when you access or use the Services. As a part of the Services, WALLT contacts such Nominees, shares your information with such Nominees, seeks legal documentation in case of your death/incapacity and provides other details, in accordance with the terms of this Agreement. Please note that any nomination registered by you on WALLT as such does not by itself create a legal right or title in favor of such person and any claim of such person shall be subject to applicable laws, rules and regulations.`,
      `You agree and provide WALLT with an implicit and explicit consent to contact your Nominees/Visitors on your behalf and also seek and verify any relevant documents, certificates etc. signifying your demise, to execute your instructions provided to WALLT, as a part of providing you the Services.`,
      `As a part of providing the Services, WALLT may:`,
      [
        `Contact you or your Nominees/Visitors at the details provided by you. By agreeing to these Terms, you agree, on behalf of yourself and your Nominees/Visitors, and consent to be contacted for such purposes, and given WALLT your implicit and explicit consent to reach you/ your Nominees/Visitors through WhatsApp, SMS, emails, calls and push notifications to verify if you are alive.`,
        `Store third-party information including name, email, phone number, and identification documents. By accepting these Terms, you agree and shall be deemed to have obtained permissions / consents from such individuals / third- parties to share their data with WALLT and for WALLT to contact them as and when required.`,
      ]
    ]
  },
  {
    title:"Subscription / Membership",
    content:[
      [
        `Your membership/ subscription with WALLT will continue year to year, for the duration chosen by you when you subscribe to WALLT, until terminated by you. To use WALLT Services you must have Internet access and a device to access WALLT’s website with all its features, a working email ID, a valid government ID (Aadhar Card, Pan Card or Indian Driving License), an Indian mobile phone number with an active connection(to receive One Time Passwords (OTPs) and provide us with one or more Payment Methods). "Payment Method" means a current, valid, accepted method of payment, as may be updated from time to time, and which may include payment through your account with a third party. Unless you cancel your membership before your yearly billing date, you authorize us to charge your next year's membership fee to your Payment Method.`,
        `We may offer a number of membership plans, including special promotional plans or memberships offered by third parties in conjunction with the provision of their own products and services. Some membership plans may have differing conditions and limitations, which will be disclosed at your sign-up or in other communications made available to you. You can find specific details regarding your membership by visiting our website and clicking on www.wallt.in.`,
        `We reserve the right to accept or reject your offer to avail the Services in part or in full.`,
      ]
    ]
  },
  {
    title:"Modifications",
    content:[
      `WALLT reserves the right to modify, update, or revise this Agreement or any part thereof at any time without prior notice or any obligation to inform you. Any changes to this Agreement will become effective immediately upon posting on the WALLT platform ("Platform"), unless the same has been specified otherwise. Your continued use of the Services after any such changes constitutes your acceptance of the modified Agreement. It is your responsibility to review this Agreement periodically for updates, or any changes, modifications or amendments, from time to time. You can access the latest version of the Terms at any given time on the website.`
    ]
  },
  {
    title:"Compliance with Applicable Laws",
    content:[
      `You agree to comply with all applicable laws, regulations, and statutes, including but not limited to Indian laws and regulations governing the use of the internet, data protection, and privacy as may be applicable in the jurisdiction. By signing up for this Agreement, You specifically agree to abide by the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, and any other relevant Indian laws pertaining to the protection of personal data and information that is provided by you to WALLT.`
    ]
  },
  {
    title:"Access and Use of Services",
    content:[
      `Account Registration: In order to access certain features of the Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. `,
      `Note! WALLT is only an aggregator and is not responsible to verify the veracity of the information provided by you. By agreeing to these Terms, you specifically agree to be responsible for the information/ data that you provide to WALLT for the purposes of these Services.`,
      `Prohibited Activities: You agree not to engage in any of the following prohibited activities while using the Services:`,
      [
        `Violating any applicable laws, regulations, or third-party rights.`,
        `Using the Services for any illegal or unauthorized purpose.`,
        `Interfering with or disrupting the integrity or performance of the Services.`,
        `Uploading, posting, or transmitting any content that is unlawful, defamatory, obscene, harmful, or infringes upon the rights of others.`,
        `Attempting to gain unauthorized access to any portion or feature of the Services.`,
        `Engaging in any conduct that restricts or inhibits any other user from using or enjoying the Services.`,
        `Reverse engineering, decompiling, disassembling, or attempting to derive the source code of the Services or any related software.`,
        `Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity.`,
        `Making, transmitting or storing electronic copies of any materials, content or other information contained on the Website, whether protected by copyright or other laws or not.`,
        `Transmitting material that encourages conduct that constitutes a criminal offence or results in civil liability or otherwise breaches any relevant laws, regulations or code of practice.`,
        `Gaining unauthorised access to other computer / network systems or interfering or disrupting networks or websites connected to WALLT.`,
        `Transmitting any information (including job posts, messages and hyperlinks) on or through the Website that is disruptive or competitive to the provision of services by us.`,
        `Harming minors in any way.`,
        `Threatening the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.`,
        `To intentionally submit on WALLT any incomplete, false or inaccurate information.`,
        `Making any unsolicited communications to other users of WALLT.`,
      ],
      `You are prohibited from violating or attempting to violate the security of the Website or WALLT, including, without limitation:`,
      [
        `refer to any website or URL that, in our sole discretion contains material that is inappropriate for the Website or any other website, contains content that would be prohibited or violates the letter or spirit of the Website.`,
        `accessing data not intended for you or logging onto a server or an account which you are not authorized to access;`,
        `attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization;`,
        `attempting to interfere with service to any other user, host or network, including, without limitation, via means of submitting a virus to the Website, overloading, 'flooding,' 'spamming', 'mail bombing' or 'crashing’;`,
        `sending unsolicited email, including promotions and/or advertising of products or services; or`,
        `forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting.`,
      ],
      `Violations of system or network security may result in civil or criminal liability. We will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Website or any activity being conducted on this Website. You agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search this Website other than the search engine and search agents available from us on this Website and other than generally available third party web browsers (e.g., Google Chrome, Firefox, Microsoft Internet Explorer).`,
    ]
  },
  {
    title:"Content",
    content:[
      `You retain any intellectual property rights that you have in your content. We do not claim ownership in any of your content, including any text, data, information, and files that you upload, share, or store in your WALLT account. However, we need you to give us some legal permissions (known as a 'licence') to use this content, including to host your content on our servers and back it up. This is solely for the purposes of providing and improving our products and services. This licence will end when your content is deleted from our Platform. You are solely responsible for contents you share and consequences thereof. WALLT does not pre-screen content, but it reserves the right to refuse or remove any content or account that in its sole discretion is found to be unlawful, offensive, threatening, promoting violence, defamatory, or violating this Agreement, or violating any other user or party's intellectual property rights.`
    ]
  },
  {
    title:"Intellectual Property Rights",
    content:[
      [
        `Ownership: We and our suppliers and licensors expressly reserve all intellectual property rights in the entire content on the website or WALLT, including but not limited to all text, programs, products, processes, technology, images, illustrations, designs, icons, photographs, music or video clips, written and other materials, which appear on the Website/ WALLT. Information displayed or transmitted on the Website/WALLT is protected by copyright and intellectual property laws. All intellectual property rights in the Services and any content provided therein are owned by or licensed to WALLT. You agree not to use, reproduce, modify, distribute, or create derivative works based on the Services or any content therein without the prior written consent of WALLT or the respective rights holder.`,
        `Trademarks: WALLT and its logos are trademarks or registered trademarks of WALLT. You agree not to use or display these trademarks in any manner without the prior written consent of WALLT.`,
        `When you use the Platform, you provide us with your files, documents, certifications, and other information regarding these documents, as well as the eKYC details from Aadhaar in the event of Aadhaar-linked accounts, eKYC through other government issued identification documents, (Pan Card or Indian Driving License) etc. You also give us and our registered issuers permission to store your documents or certificates electronically. Unless you explicitly provide the permission (to your nominees/visitors), no one can access your documents/ information.`,
        `We grant you a limited, revocable, non-exclusive, non-sublicensable, and non-transferable license to use our Services, subject to and in accordance with the Agreement. This license is for the sole purpose of enabling you to use our Services in the manner permitted by the Agreement. Violation of any of the Terms will result in the termination of your account.`,
        `All intellectual property rights in any data or information generated from the use of Services or WALLT (other than your personal data) shall be owned by WALLT and you hereby assign all such rights to WALLT, to the fullest extent under law.`,
        `Access to the Website or use of Services does not confer and shall not be considered as conferring upon anyone, any license under any of our or any third party's intellectual property rights. You shall not copy, store, modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way exploit any of the content available on the Website or WALLT either in whole or in part. Any use of the Services or its contents, including copying or storing it or them in whole or part, other than strictly for your own personal non-commercial use is strictly prohibited without our prior express permission. You may not modify, distribute or re-post anything on the Website for any purpose. No trademark or service mark license is granted to you in connection with the materials contained on the Website or WALLT. Access to the Website or use of Services does not authorize anyone to use our name, logo or mark. References on the Website to any names, marks, products or services of third parties or hypertext links to third party websites or information are provided solely as a convenience to you and do not in any way constitute or imply our endorsement, sponsorship or recommendation of the third party, information, product or service.`
      ]
    ]
  },
  {
    title:"Termination",
    content:[
      `WALLT reserves the right to suspend or terminate your access to the Services at any time by providing a prior written notice to you. Upon termination, all provisions of this Agreement which by their nature should survive termination shall survive, including but not limited to, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. In case you choose to discontinue using WALLT’s Services, you can communicate the same to WALLT by writing an email to us at support@wallt.in. Upon receipt of such a request, and subsequent confirmation from you, WALLT will delete all your data (personal data, account information, payment methods, etc.) within 72 hours of such confirmation, save and except for data which may be retained (subject to privacy policy) for audit purposes or in compliance of any applicable law. In case you wish to opt for WALLT’s service again in future, you will be required to create a fresh account, as per the process provided.`,
      `Please note in case you choose to discontinue using the services during an active subscription, WALLT will not be responsible for refunding the amount of subscription fee paid by you for the remaining duration, i.e., for the unused/ unutilized period of the subscription plan.`,
      `In the event you fail to pay the subscription fee to WALLT, WALLT will store your data for a period of 6 months from the date of your last day of active subscription, post which, all your data (personal data, account information, payment methods, etc.) will be deleted. You are requested to download all your data within such a period of 6 months to prevent any loss of information accruing due to such data deletion by WALLT.`
    ]
  },
  {
    title:"Disclaimers",
    content:[
      [
        `THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WALLT DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS.`,
        `WALLT DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.WALLT MAKES NO REPRESENTATIONS OR GUARANTEES THE ACCURACY, INTEGRITY, OR QUALITY OF THE INFORMATION, DATA, TEXT, SOFTWARE, SOUND, PHOTOGRAPHS, GRAPHICS, VIDEOS, MESSAGES OR OTHER MATERIALS AVAILABLE ON THE WEBSITE OR THROUGH THE SERVICES.`,
        `WALLT HAS TAKEN ALL CARE AND PRECAUTION TO PROVIDE ACCURATE DATA AND INFORMATION ON THE WEBSITE, AS FAR AS POSSIBLE. HOWEVER, YOU MUST BEAR THE RISKS ASSOCIATED WITH THE USE OF THE SERVICES. WHILE WALLT TRIES TO ENSURE THAT THE CONTENTS ON THE WEBSITE ARE CORRECT AND THAT THE SERVICES ARE ERROR FREE, WALLT SHALL NOT BE HELD LIABLE OR RESPONSIBLE IF THIS IS NOT THE CASE. WALLT WILL NOT BE RESPONSIBLE FOR ANY ERRORS OR OMISSIONS OR FOR THE RESULTS OBTAINED FROM THE USE OF ANY INFORMATION OR USE OF THE SERVICES OR FOR ANY TECHNICAL PROBLEMS YOU MAY EXPERIENCE WITH WALLT.`,
        `WALLT ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE HELD LIABLE FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT YOUR EQUIPMENT AS A RESULT OF ACCESS TO, USE OF, OR BROWSING THE WEBSITE OR USE OF WALLT OR THE DOWNLOADING OF ANY MATERIAL, DATA, TEXT, IMAGES, VIDEO CONTENT, OR AUDIO CONTENT FROM THE WEBSITE OR WALLT. WALLT ASSUMES NO LIABILITY WHATSOEVER FOR ANY MONETARY OR OTHER DAMAGE SUFFERED BY YOU ON ACCOUNT OF ANY DELAY, FAILURE, INTERRUPTION, OR CORRUPTION OF ANY DATA OR OTHER INFORMATION TRANSMITTED IN CONNECTION WITH USE OF THE WEBSITE OR WALLT; AND/OR ANY INTERRUPTION OR ERRORS IN THE OPERATION OF THE WEBSITE OR WALLT.`
      ],
      `Note: Please note that WALLT is currently in a beta testing stage and is being offered to you on an early access basis. Some of the features mentioned on WALLT’s webpages may not be available or may not have gone live. Further, during such times, there may be short disruption in the Services provided by WALLT. WALLT will not be liable for any deficiency of services during this period and by using the Services you expressly agree and acknowledge the same.`
    ]
  },
  {
    title:"Limitation of Liability and Indemnity",
    content:[
      `IN NO EVENT SHALL WALLT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE LOSS OR DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SERVICES, EVEN IF WALLT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF OPPORTUNITY, LOSS OF GOODWILL ETC. IN NO EVENT SHALL WALLT’S LIABILITY FOR ANY ONE OR MORE CONNECTED CLAIMS SHALL EXCEED THE AMOUNT OF SUBSCRIPTION FEE PAID BY YOU FOR A PERIOD OF 6 (SIX) MONTHS PRIOR TO THE DATE WHEN THE CLAIM ARISES.`,
      `You agree to defend, indemnify and hold us, our employees, directors, officers, agents and their successors and assigns harmless, from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon your actions or inactions by using the Services, or due to your breach of any the Agreement or arising out of your violation or breach of any applicable laws, rules or regulations.`,
    ]
  },
  {
    title:"Governing Law and Dispute Resolution",
    content:[
      `This Agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions. The courts in New Delhi shall have the exclusive jurisdiction in respect of any disputes arising out of or in relation to this Agreement.`
    ]
  },
  {
    title:"Entire Agreement",
    content:[
      `This Agreement constitutes the entire agreement between you and WALLT regarding the subject matter herein and supersedes all prior or contemporaneous agreements, understandings, negotiations, and discussions, whether oral or written, between the parties regarding the subject matter herein.`,
      `Contact Information`,
      `If you have any questions, comments, or concerns about this Agreement or the Services, please contact us at support@wallt.in.`,
      `When you visit the Website or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you primarily by email or by posting notices on the Website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. When you submit your phone number along with your address on the Website or request our call back, you also consent to receive all communications (including via messages) on that number for communication.`
    ]
  }
]



