import { createIcon } from "@chakra-ui/icons";

const PriceListIcon = createIcon({
  displayName: "PriceListIcon",
  viewBox: "0 0 29 30",
  path: (
    <>
      <g filter="url(#filter0_b_70_5519)">
        <path
          d="M6 11.21v6.58c0 1.043.55 2.007 1.44 2.53l5.62 3.289a2.85 2.85 0 0 0 2.88 0l5.62-3.29A2.928 2.928 0 0 0 23 17.79v-6.578a2.93 2.93 0 0 0-1.44-2.53l-5.62-3.29a2.85 2.85 0 0 0-2.88 0l-5.62 3.29A2.927 2.927 0 0 0 6 11.21Z"
          stroke="url(#paint0_linear_70_5519)"
          stroke-opacity=".26"
        />
      </g>
      <g filter="url(#filter1_f_70_5519)">
        <path
          d="M11 13.615v2.77c0 .44.226.845.593 1.065l2.314 1.385c.367.22.819.22 1.186 0l2.314-1.385c.367-.22.593-.625.593-1.065v-2.77c0-.44-.226-.845-.593-1.065l-2.314-1.385a1.15 1.15 0 0 0-1.186 0l-2.314 1.385c-.367.22-.593.626-.593 1.065Z"
          fill="#7A32F1"
        />
      </g>
      <g filter="url(#filter2_b_70_5519)">
        <path
          d="M11 13.115v2.77c0 .44.226.845.593 1.065l2.314 1.385c.367.22.819.22 1.186 0l2.314-1.385c.367-.22.593-.625.593-1.065v-2.77c0-.44-.226-.845-.593-1.065l-2.314-1.385a1.15 1.15 0 0 0-1.186 0l-2.314 1.385c-.367.22-.593.626-.593 1.065Z"
          fill="#7A32F1"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_70_5519"
          x="-2.41"
          y="-3.41"
          width="33.82"
          height="35.82"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.955" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_70_5519"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_70_5519"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_70_5519"
          x=".09"
          y=".09"
          width="28.82"
          height="29.82"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation="5.455"
            result="effect1_foregroundBlur_70_5519"
          />
        </filter>
        <filter
          id="filter2_b_70_5519"
          x="3.09"
          y="2.59"
          width="22.82"
          height="23.82"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.955" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_70_5519"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_70_5519"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_70_5519"
          x1="14.5"
          y1="5"
          x2="14.5"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff" />
          <stop offset=".438" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default PriceListIcon;
