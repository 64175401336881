import { createIcon } from "@chakra-ui/icons";

const MaleAvatarBlue = createIcon({
  displayName: "MaleAvatarBlue",
  viewBox: "0 0 75 75",
  path: (
    <>
      <path
        d="M37.236 73.484c20.13 0 36.45-16.32 36.45-36.45 0-20.13-16.32-36.45-36.45-36.45-20.13 0-36.45 16.32-36.45 36.45 0 20.13 16.32 36.45 36.45 36.45Z"
        fill="#364147"
      />
      <path
        d="M36.958 52.39c8.924 0 16.159-7.234 16.159-16.159 0-8.924-7.235-16.159-16.159-16.159S20.8 27.308 20.8 36.233c0 8.924 7.235 16.158 16.16 16.158Z"
        fill="#333"
      />
      <path
        opacity=".1"
        d="M31.28 50.886h11.276v9.02a5.636 5.636 0 0 1-11.272 0v-9.02h-.003Z"
        fill="#000"
      />
      <path
        d="M32.354 50.469h9.122a1.076 1.076 0 0 1 1.076 1.076v7.943a5.636 5.636 0 0 1-11.271 0v-7.943a1.076 1.076 0 0 1 1.073-1.076Z"
        fill="#BE7C5E"
      />
      <path
        opacity=".1"
        d="M31.303 55.143a16.227 16.227 0 0 0 11.275.032V53.79H31.303v1.353Z"
        fill="#000"
      />
      <path
        d="M36.958 55.898c8.924 0 16.159-7.234 16.159-16.159 0-8.924-7.235-16.158-16.159-16.158S20.8 30.815 20.8 39.739c0 8.925 7.235 16.16 16.16 16.16Z"
        fill="#BE7C5E"
      />
      <path
        d="M27.166 33.076c.19-.698.427-1.466 1.053-1.829.495-.289 1.109-.244 1.68-.215 4.467.244 8.895-.774 13.239-1.838 2.484-.607 5.14-1.346 6.747-3.348 1.607-2.002-.488-8.293-2.933-9.035-1.607-.492-3.47.424-4.993-.296-.678-.321-1.176-.922-1.761-1.391-1.199-.964-2.738-1.353-4.229-1.72-1.741-.43-3.624-.85-5.279-.16-1.285.534-2.223 1.661-3.441 2.33-2.317 1.285-5.141.687-7.795.68-.896 0-1.966.18-2.323 1.003-.241.56-.048 1.196-.023 1.803.078 1.928-1.513 3.428-2.846 4.82-1.334 1.39-2.593 3.377-1.752 5.105.322.659.91 1.157 1.244 1.81.575 1.13.286 2.496.495 3.746.209.948.563 1.859 1.05 2.699.99 1.928 5.96 12.306 8.245 9.668 1.106-1.285 1.286-5 1.732-6.67a572.559 572.559 0 0 1 1.89-7.162Z"
        fill="#333"
      />
      <path
        d="M25.462 41.97c.83 0 1.504-1.262 1.504-2.819 0-1.556-.673-2.817-1.504-2.817-.83 0-1.503 1.261-1.503 2.817 0 1.557.673 2.818 1.503 2.818Z"
        fill="#BE7C5E"
      />
      <path
        d="M37.306 73.446c20.13 0 36.45-16.32 36.45-36.45 0-20.13-16.32-36.45-36.45-36.45-20.13 0-36.45 16.32-36.45 36.45 0 20.13 16.32 36.45 36.45 36.45Z"
        fill="#080F16"
      />
      <path
        d="M61.462 64.375C48.328 75.458 29.35 76.223 15.366 66.233l9.694-5.093a9.446 9.446 0 0 0 1.318-.837c.126-.096.252-.204.372-.3a9.436 9.436 0 0 0 2.463-3.185c.44-.944.715-1.955.815-2.99.069-.673.088-1.349.059-2.023-.043-1.166-.167-2.325-.23-3.492-.039-.643-.074-1.29-.108-1.933l.407.19 1.084.499.495.06 5.715.712 6.382.794.483 6.498.157 2.125.077 1.032v.068l2.506.892 14.407 5.125Z"
        fill="#CD887E"
      />
      <path
        d="M61.462 64.375C48.328 75.458 29.35 76.223 15.366 66.233l9.694-5.093a9.446 9.446 0 0 0 1.318-.837c.126-.096.252-.204.372-.3 4.948 3.197 11.583 5.062 17.125 2.264 1.32-.665 2.514-1.712 3.188-3.01l14.4 5.118Z"
        fill="url(#male-avatar-blue-a)"
      />
      <path
        d="M44.558 58.366c-2.123.224-4.528-.285-6.797-1.462-4.262-2.215-6.67-6.299-6.026-9.766l5.716.711 6.382.795.482 6.498.243 3.224Z"
        fill="#BC7A70"
      />
      <path
        d="M53.84 24.684c.562 1.061.89 2.23.961 3.429.237 4.308-2.093 8.586-4.77 11.804l-.057.07c-2.055 2.36-5.433 3.04-8.223 4.134-1.005.395-11.894 4.023-11.864 4.708 0-.149-1.134-1.178-2.562-2.416a16.25 16.25 0 0 1-5.172-8.594c-.644-2.815-.367-6.34.342-9.126 1.28-5.023 5.335-8.42 10.173-9.879 4.685-1.412 10.78-1.48 15.29.55 2.413 1.088 4.656 2.96 5.882 5.32Z"
        fill="#F2D4CF"
      />
      <circle cx="27.491" cy="40.781" r="3.463" fill="#CD887E" />
      <circle cx="28.588" cy="40.73" r="3.1" fill="#BC7A70" />
      <path
        d="m28.488 37.462.746 6.11.489 4.001c.179 1.473.794 2.33 1.809 3.463a17.357 17.357 0 0 0 6.581 4.583c2.406.945 5.726 1.846 8.199.713 2.526-1.159 3.22-4.086 3.978-6.518.758-2.432 1.446-5.109 1.29-7.694-.204-3.51-1.223-5.438-1.223-5.438l.49-4.074c.184-1.538.441-3.15 0-4.67-.514-1.74-1.877-2.444-3.03-3.7-2.717 3.84-7.36 6.15-11.407 8.47-2.088 1.195-7.922 4.754-7.922 4.754Z"
        fill="#CD887E"
      />
      <path
        d="M43.649 36.754c-.012.071 3.07 7.708 3.07 7.708l-3.642.34M34.225 35.84a16.43 16.43 0 0 1 5.718-.418M46.953 35.075c1.151-.324 2.346-.461 3.54-.408M36.57 47.945a8.08 8.08 0 0 1 2.971-3.078M39.805 38.689a2.361 2.361 0 0 1-1.344 1.955 4.832 4.832 0 0 1-1.292.338"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <path
        d="M53.272 32.819c.15-2.32-.098-4.73-1.194-6.782a7.614 7.614 0 0 0-2.933-3.055c-.886-.497-2.852-1.322-3.754-.485-1.866 1.717 2.322 5.883 3.39 6.936a20.957 20.957 0 0 0 4.491 3.386Z"
        fill="#F2D4CF"
      />
      <path
        d="m39.788 32.084 6.433-.287M39.03 33.452l6.431-.29M46.463 37.925c.218.51.496.992.827 1.436.325.409.792.679 1.308.756"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <path
        d="M38.99 47.126A.379.379 0 0 1 39.53 47c.434.277 1.237.874 2.345 1.043 1.511.23 2.784-.426 3.112-.053.184.203-.018.485-.407.843a4.025 4.025 0 0 1-2.99.886c-1.453-.203-2.61-1.833-2.636-2.418a.349.349 0 0 1 .034-.175Z"
        fill="#fff"
      />
      <path
        d="M37.673 37.534c1.24-.069 1.363 1.834.122 1.917h-.055c-1.24.07-1.363-1.833-.124-1.917h.057ZM47.882 36.733c1.153-.065 1.265 1.695.114 1.779h-.051c-1.151.065-1.265-1.693-.114-1.776h.05v-.003Z"
        fill="#00214E"
      />
      <defs>
        <linearGradient
          id="male-avatar-blue-a"
          x1="18.813"
          y1="74.373"
          x2="56.9"
          y2="55.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FCECB" />
          <stop offset="1" stopColor="#6878B1" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default MaleAvatarBlue;
