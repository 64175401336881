import { createIcon } from "@chakra-ui/icons";

const MaleAvatarPurple = createIcon({
  displayName: "MaleAvatarPurple",
  viewBox: "0 0 75 75",
  path: (
    <>
      <path
        d="M37.237 73.484c20.13 0 36.45-16.319 36.45-36.45 0-20.13-16.32-36.45-36.45-36.45-20.131 0-36.45 16.32-36.45 36.45 0 20.131 16.319 36.45 36.45 36.45Z"
        fill="#364147"
      />
      <path
        d="M36.958 52.39c8.924 0 16.159-7.234 16.159-16.159 0-8.924-7.235-16.159-16.16-16.159-8.924 0-16.158 7.235-16.158 16.16 0 8.924 7.234 16.158 16.159 16.158Z"
        fill="#333"
      />
      <path
        opacity=".1"
        d="M31.281 50.886h11.275v9.02a5.636 5.636 0 0 1-11.271 0v-9.02h-.004Z"
        fill="#000"
      />
      <path
        d="M32.354 50.469h9.123a1.076 1.076 0 0 1 1.076 1.076v7.943a5.636 5.636 0 0 1-11.272 0v-7.943a1.076 1.076 0 0 1 1.073-1.076Z"
        fill="#BE7C5E"
      />
      <path
        opacity=".1"
        d="M31.304 55.144a16.226 16.226 0 0 0 11.275.032v-1.385H31.304v1.353Z"
        fill="#000"
      />
      <path
        d="M36.958 55.899c8.924 0 16.159-7.235 16.159-16.16 0-8.924-7.235-16.158-16.16-16.158-8.924 0-16.158 7.234-16.158 16.159 0 8.924 7.234 16.159 16.159 16.159Z"
        fill="#BE7C5E"
      />
      <path
        d="M27.166 33.076c.19-.697.427-1.465 1.054-1.828.495-.29 1.109-.245 1.68-.216 4.467.244 8.895-.774 13.239-1.838 2.483-.607 5.14-1.346 6.747-3.348 1.607-2.002-.488-8.293-2.933-9.035-1.607-.492-3.47.424-4.994-.296-.678-.321-1.176-.922-1.76-1.391-1.199-.964-2.738-1.353-4.229-1.719-1.741-.43-3.624-.851-5.279-.16-1.285.533-2.223 1.66-3.441 2.329-2.317 1.285-5.141.688-7.795.681-.897 0-1.967.18-2.323 1.003-.242.559-.049 1.195-.023 1.802.077 1.928-1.513 3.429-2.847 4.82-1.333 1.391-2.593 3.377-1.751 5.106.321.658.91 1.156 1.243 1.809.576 1.13.286 2.496.495 3.746.21.949.564 1.859 1.051 2.7.99 1.927 5.96 12.306 8.245 9.667 1.105-1.285 1.285-5 1.732-6.67a572.118 572.118 0 0 1 1.89-7.162Z"
        fill="#333"
      />
      <path
        d="M25.463 41.97c.83 0 1.503-1.262 1.503-2.819 0-1.556-.673-2.817-1.503-2.817-.83 0-1.504 1.261-1.504 2.817 0 1.557.673 2.818 1.504 2.818Z"
        fill="#BE7C5E"
      />
      <path
        d="M37.306 73.446c20.13 0 36.45-16.319 36.45-36.45 0-20.13-16.32-36.45-36.45-36.45-20.13 0-36.45 16.32-36.45 36.45 0 20.131 16.32 36.45 36.45 36.45Z"
        fill="#080F16"
      />
      <path
        d="M60.41 64.88c-13.773 10.606-32.984 10.52-46.663-.206l10.026-4.7a9.652 9.652 0 0 0 1.76-1.07 9.55 9.55 0 0 0 2.632-3.11 9.21 9.21 0 0 0 .956-2.983c.101-.676.152-1.359.153-2.042 0-.713-.019-1.426-.043-2.139a79.64 79.64 0 0 1-.033-1.4 471.49 471.49 0 0 1-.021-1.958l.412.206 1.072.554.496.084 5.744.977 6.412 1.092.194 6.593.064 2.155.031 1.047v.064l.206.087 2.28.927 14.323 5.822Z"
        fill="#F2A196"
      />
      <path
        d="M43.598 58.044a3.297 3.297 0 0 1-2.075-.322L29.23 47.674c-.015-.466-.027.02-.033-.445-.008-.653-.015-1.306-.02-1.96l.411.206 1.072.554.496.085 5.745.977 6.412 1.091.194 6.594.063 2.155.031 1.047v.066h-.002Z"
        fill="#E88870"
      />
      <path
        d="M60.41 64.88c-13.773 10.605-32.984 10.52-46.663-.206l10.026-4.7a9.643 9.643 0 0 0 1.76-1.071c4.851 3.453 11.474 5.63 17.201 3.053 1.364-.618 2.617-1.615 3.359-2.897l14.318 5.821Z"
        fill="url(#male-avatar-purple-a)"
      />
      <path
        d="M56.723 23.614a5.263 5.263 0 0 1-.068.36 6.606 6.606 0 0 1-.682 1.75l-.017.029c-.117.206-.243.412-.379.618-.36.529-.754 1.035-1.178 1.514l-.254.282c-.171.192-.344.38-.517.565.917-.206 1.805.649 1.968 1.572.162.923-.206 1.854-.662 2.679-.797 1.458-1.947 2.429-2.959 3.696-.758.944-1.178 2.06-2.004 2.963l-.062.068c-2.182 2.291-5.627 2.827-8.495 3.806-1.03.354-12.202 3.527-12.202 4.22 0-.151-1.094-1.237-2.473-2.558-4.945-4.695-6.57-11.95-3.628-18.173 3.163-6.696 10.345-11.474 17.186-13.77 2.139-.716 4.566-1.198 6.593-.205 2.028.993 3.037 4.073 1.356 5.577a5.39 5.39 0 0 1 3.694-1.073 5.04 5.04 0 0 1 3.24 1.456 5.563 5.563 0 0 1 1.431 2.49c.187.695.225 1.422.112 2.134Z"
        fill="#5D3624"
      />
      <path
        d="M56.72 23.614a5.315 5.315 0 0 1-.068.36 1.94 1.94 0 0 0-.055.114c-.233.531-.34 1.104-.619 1.636l-.016.029c-.117.206-.243.412-.38.618a13.83 13.83 0 0 1-1.178 1.514l-.261.283c-.171.191-.344.379-.517.564.916-.206 1.805.65 1.967 1.572.163.923-.206 1.855-.661 2.679-.797 1.459-1.947 2.429-2.959 3.696-.758.944-1.178 2.06-2.005 2.963a7.768 7.768 0 0 1-1.76-4.012c-.337-2.732-.131-5.709-.224-8.474-.058-1.74-.618-2.06.425-3.468a15.31 15.31 0 0 1 2.884-2.738c.84-.554 1.922-.64 2.926-.499.874.12 1.7.476 2.386 1.03.187.695.227 1.422.115 2.133Z"
        fill="#442619"
      />
      <circle cx="27.149" cy="39.5" r="3.503" fill="#F2A196" />
      <circle cx="28.261" cy="39.5" r="3.136" fill="#E88870" />
      <path
        d="m28.308 36.202.906 11.745 13.69 7.998a4.073 4.073 0 0 0 5.08-2.023c1.97-4.034 5.131-10.405 4.715-12.775-.618-3.502-2.267-4.739-2.267-4.739.23-1.373.458-2.743.684-4.108.26-1.547.592-3.163.206-4.718-.438-1.785-1.786-2.545-2.895-3.878-2.917 3.758-7.714 5.883-11.902 8.036-2.16 1.12-8.217 4.462-8.217 4.462Z"
        fill="#F2A196"
      />
      <path
        d="M38.428 35.75c1.256 0 1.256 1.933 0 1.943h-.058c-1.257 0-1.257-1.932 0-1.943h.058ZM48.756 35.464c1.168.01 1.168 1.792 0 1.802h-.052c-1.166 0-1.166-1.792 0-1.802h.052Z"
        fill="#00214E"
      />
      <path
        d="M43.662 35.192c-.016.072 2.753 7.444 2.753 7.444l-3.696.18M34.909 33.766a16.599 16.599 0 0 1 5.796-.163M47.078 33.788a11.403 11.403 0 0 1 3.595-.25"
        stroke="#00214E"
        strokeWidth=".5"
      />
      <path
        d="M38.933 44.877a.381.381 0 0 1 .552-.103c.429.301 1.212.94 2.325 1.16 1.516.303 2.83-.304 3.146.087.177.22-.04.488-.454.824-.876.655-1.98.93-3.061.76-1.46-.274-2.555-1.963-2.555-2.563a.344.344 0 0 1 .047-.165Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="male-avatar-purple-a"
          x1="13.747"
          y1="72.775"
          x2="60.411"
          y2="72.775"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#390D98" />
          <stop offset="1" stopColor="#6878B1" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default MaleAvatarPurple;
