import { Box, Center, Stack, useMediaQuery } from "@chakra-ui/react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef, useState } from "react";
import InfoCard from "./InfoCard";
import {
  AssuredContent,
  OrganizedContent,
  ProtectedContent,
} from "./staticData";
import Shield from "../../icons/Shield";
import Tick from "../../icons/Tick";
import Folder from "../../icons/Folder";
import Vault from "../../icons/Vault";
import { useResponsive } from "../../providers/useMediaProvider";

const ProtectedFold = () => {
  const protectedFoldRef = useRef(null);
  const { isLargerThan768 } = useResponsive();

  const { scrollYProgress } = useScroll({
    target: protectedFoldRef,
  });
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const [foldStyle, setFoldStyle] = useState<any>({
    position: "sticky",
  });

  const [opacity, setOpacity] = useState({
    folder: 0.8,
    tick: 0.8,
    shield: 0.8,
  });

  const [display, setDisplay] = useState({
    organized: "flex",
    protected: "none",
    assured: "none",
  });

  const [organizedConStyle, setOrganizedConStyle] = useState({
    transform: "translateY(60%)",
    transition: "transform 330ms ease-in-out",
  });

  const [protectedConStyle, setProtectedConStyle] = useState({
    transform: "translateY(150%)",
    transition: "transform 330ms ease-in-out",
  });

  const [assuredConStyle, setAssuredConStyle] = useState({
    transform: "translateY(150%)",
    transition: "transform 330ms ease-in-out",
  });

  const [folderStyle, folderApi] = useState({
    transform:
      window.innerWidth > 800
        ? "translate(205%, 219%) scale(1)"
        : "translate(200%, 240%) scale(1)",
  });

  const [tickStyle, tickApi] = useState({
    transform:
      window.innerWidth > 800
        ? "translate(140%, -33%) scale(1)"
        : "translate(120%, -38%) scale(1)",
  });

  const [shieldStyle, shieldApi] = useState({
    transform:
      window.innerWidth > 800
        ? "translate(-24%, 147%) scale(1)"
        : "translate(-25%, 195%) scale(1)",
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0.66) {
      setFoldStyle({
        position: "sticky",
      });
      shieldApi({
        transform: "translate(-5%, 148%) scale(1)",
      });
      tickApi({
        transform: "translate(138%, -30%) scale(1)",
      });
      folderApi({
        transform: "translate(205%, 219%) scale(2.6)",
      });
      setOpacity({
        folder: 1,
        tick: 0.8,
        shield: 0.8,
      });
      setDisplay({
        organized: "none",
        protected: "none",
        assured: "flex",
      });
    } else if (latest > 0.33) {
      setFoldStyle({
        position: "sticky",
      });
      shieldApi({
        transform: "translate(-5%, 148%) scale(1)",
      });
      tickApi({
        transform: "translate(150%, -32%) scale(2.6)",
      });
      folderApi({
        transform: "translate(205%, 219%) scale(1)",
      });
      setOpacity({
        folder: 0.8,
        tick: 1,
        shield: 0.8,
      });
      setDisplay({
        organized: "none",
        protected: "flex",
        assured: "none",
      });
    } else if (latest > 0) {
      setFoldStyle({
        position: "sticky",
      });
      shieldApi({
        transform: "translate(-110%, 138%) scale(2.2)",
      });
      tickApi({
        transform: "translate(140%, -33%) scale(1)",
      });
      folderApi({
        transform: "translate(205%, 219%) scale(1)",
      });
      setOpacity({
        folder: 0.8,
        tick: 0.8,
        shield: 1,
      });
      setDisplay({
        organized: "flex",
        protected: "none",
        assured: "none",
      });
    } else {
      setFoldStyle({
        position: "sticky",
      });
      folderApi({
        transform: "translate(-15%%, 154%) scale(1)",
      });
      shieldApi({
        transform: "translate(-24%, 147%) scale(1)",
      });
      tickApi({
        transform: "translate(140%, -33%) scale(1)",
      });
      setDisplay({
        organized: "flex",
        protected: "none",
        assured: "none",
      });
      setOpacity({
        folder: 0.8,
        tick: 0.8,
        shield: 0.8,
      });
    }
    if (!isLargerThan768) {
      if (latest > 0.7) {
        setOrganizedConStyle((prev) => ({
          ...prev,
          transform: `translateY(-250%)`,
        }));
        setProtectedConStyle((prev) => ({
          ...prev,
          transform: `translateY(-250%)`,
        }));
        setAssuredConStyle((prev) => ({
          ...prev,
          transform: `translateY(${150 - 160 * latest}%)`,
        }));
      } else if (latest > 0.44) {
        setOrganizedConStyle((prev) => ({
          ...prev,
          transform: `translateY(-250%)`,
        }));
        setProtectedConStyle((prev) => ({
          ...prev,
          transform: `translateY(${150 - 250 * latest}%)`,
        }));
        setAssuredConStyle((prev) => ({
          ...prev,
          transform: "translateY(150%)",
        }));
      } else if (latest > 0.03) {
        setOrganizedConStyle((prev) => ({
          ...prev,
          transform: `translateY(${60 - 350 * latest}%)`,
        }));
        setProtectedConStyle((prev) => ({
          ...prev,
          transform: "translateY(150%)",
        }));
        setAssuredConStyle((prev) => ({
          ...prev,
          transform: "translateY(150%)",
        }));
      } else {
        setOrganizedConStyle((prev) => ({
          ...prev,
          transform: "translateY(60%)",
        }));
        setProtectedConStyle((prev) => ({
          ...prev,
          transform: "translateY(150%)",
        }));
        setAssuredConStyle((prev) => ({
          ...prev,
          transform: "translateY(150%)",
        }));
      }
    }
  });

  return (
    <Box ref={protectedFoldRef} sx={foldStyle} height="200vh">
      <div
        style={{
          position: "sticky",
          top: isLargerThan768 ? "10%" : "22%",
          marginTop: isLargerThan768 ? "10%" : "40%",
        }}
      >
        <Stack direction="row" width="100%">
          {isLargerThan800 && (
            <Center flex={1}>
              <Box
                sx={{
                  background: "#080F16B2",
                  width: "40vw",
                  height: "85vh",
                  borderRadius: "50px",
                }}
              >
                <InfoCard data={OrganizedContent} display={display.organized} />
                <InfoCard data={ProtectedContent} display={display.protected} />
                <InfoCard data={AssuredContent} display={display.assured} />
              </Box>
            </Center>
          )}
          <Center flex={1} position="relative">
            <Box
              width={{ base: 167, md: 387 }}
              height={{ base: 230, md: 503 }}
              position="relative"
            >
              <Box
                style={{
                  position: "absolute",
                  width: isLargerThan800 ? "97px" : "43px",
                  height: isLargerThan800 ? "148px" : "67px",
                  transition: "transform 330ms ease-in-out",
                  ...shieldStyle,
                }}
              >
                <Shield
                  width={{ base: "68%", md: "100%" }}
                  height={{ base: "68%", md: "100%" }}
                  fill="none"
                  opacity={opacity.shield}
                />
              </Box>
              <Box
                style={{
                  position: "absolute",
                  width: isLargerThan800 ? "98px" : "48px",
                  height: isLargerThan800 ? "98px" : "48px",
                  transition: "transform 330ms ease-in-out",
                  ...tickStyle,
                }}
              >
                <Tick
                  width="100%"
                  height="100%"
                  fill="none"
                  opacity={opacity.tick}
                />
              </Box>
              <Box
                style={{
                  position: "absolute",
                  width: isLargerThan800 ? "117px" : "52px",
                  height: isLargerThan800 ? "108px" : "48px",
                  ...folderStyle,
                  transition: "transform 330ms ease-in-out",
                }}
              >
                <Folder
                  width="100%"
                  height="100%"
                  fill="none"
                  opacity={opacity.folder}
                />
              </Box>
              <Vault width="100%" height="100%" fill="none" />
            </Box>
          </Center>
        </Stack>
        {!isLargerThan768 && (
          <Center flexDir="column" flex={1}>
            <Box
              sx={{
                background: "#080F16B2",
                width: "90vw",
                borderRadius: "50px",
                position: "absolute",
                ...organizedConStyle,
              }}
            >
              <InfoCard data={OrganizedContent} />
            </Box>
            <Box
              sx={{
                background: "#080F16B2",
                width: "90vw",
                borderRadius: "50px",
                position: "absolute",

                ...protectedConStyle,
              }}
            >
              <InfoCard data={ProtectedContent} />
            </Box>
            <Box
              sx={{
                background: "#080F16B2",
                width: "90vw",
                borderRadius: "50px",
                position: "absolute",
                ...assuredConStyle,
              }}
            >
              <InfoCard data={AssuredContent} />
            </Box>
          </Center>
        )}
      </div>
    </Box>
  );
};

export default ProtectedFold;
