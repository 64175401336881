import { createIcon } from "@chakra-ui/icons";

const RealState = createIcon({
  displayName: "React State Icon",
  viewBox: "0 0 41 40",
  path: (
    <>
      <path
        opacity=".2"
        d="M26.65 6.397H13.29c-1.402 0-2.474 1.073-2.474 2.475V34.44c0 1.403 1.072 2.475 2.474 2.475h18.97c1.402 0 2.474-1.072 2.474-2.475V14.48c0-.412-.164-.824-.494-1.154l-6.434-6.434a1.629 1.629 0 0 0-1.154-.495Z"
        fill="#6B7DFF"
      />
      <path
        d="M16.79 31.628h5.416v-7.564a.614.614 0 0 0-.173-.426.58.58 0 0 0-.417-.177H17.38a.588.588 0 0 0-.417.177.606.606 0 0 0-.173.426v7.564ZM14.04 16.35c.372 0 .672.307.672.686 0 .38-.3.686-.671.686h-3.933V30.55c0 .297.119.567.31.762.19.195.455.317.746.317h4.283v-7.564c0-.542.218-1.038.568-1.396a1.91 1.91 0 0 1 1.367-.58h4.235c.531 0 1.017.222 1.367.58.35.358.568.852.568 1.396v7.564h4.283c.291 0 .555-.12.745-.315.193-.197.311-.466.311-.764V17.723h-11.7a.679.679 0 0 1-.67-.686c0-.38.3-.686.67-.686h13.767c.19 0 .345-.08.456-.206a.87.87 0 0 0 .176-.306c.042-.122.066-.261.066-.41 0-.331-.127-.678-.407-.937l-2.587-2.38a.69.69 0 0 1-.252-.534V6.475h-2.373v1.683a.699.699 0 0 1-.174.458.66.66 0 0 1-.947.051L20.844 4.92a2.136 2.136 0 0 0-.638-.409 1.892 1.892 0 0 0-1.411.002c-.226.09-.442.226-.639.407L7.751 14.492c-.28.259-.406.606-.406.937 0 .149.022.29.065.41.041.115.1.22.176.305a.596.596 0 0 0 .457.207h5.999-.001Zm16.194 1.372V30.55c0 .671-.27 1.284-.705 1.729l-.004.004a2.368 2.368 0 0 1-1.69.718H11.163a2.366 2.366 0 0 1-1.693-.72 2.47 2.47 0 0 1-.707-1.731V17.723h-.723c-.6 0-1.096-.257-1.453-.663a2.291 2.291 0 0 1-.438-.761 2.643 2.643 0 0 1 .704-2.826L17.26 3.9c.316-.291.67-.514 1.044-.665A3.186 3.186 0 0 1 19.5 3c.402 0 .806.08 1.195.235.374.15.728.372 1.047.664l2.952 2.715v-.83c0-.379.3-.685.671-.685h3.717c.37 0 .671.306.671.686v5.486l2.392 2.2a2.645 2.645 0 0 1 .704 2.826c-.1.282-.248.542-.44.76a1.891 1.891 0 0 1-1.453.665h-.723v.001Z"
        fill="#B865FF"
      />
    </>
  ),
});

export default RealState;
