import { createIcon } from "@chakra-ui/icons";

const EllipsisMob = createIcon({
  displayName: "EllipsisMob",
  viewBox: "0 0 346 69",
  path: (
    <>
      <path
        d="M344.705 34.233c0 2.17-1.111 4.345-3.354 6.51-2.244 2.166-5.58 4.278-9.922 6.305-8.681 4.054-21.274 7.717-36.875 10.798-31.195 6.16-74.311 9.975-121.952 9.975-47.64 0-90.757-3.814-121.951-9.975-15.601-3.081-28.195-6.744-36.876-10.797-4.341-2.028-7.677-4.14-9.921-6.306C1.61 38.578.5 36.403.5 34.233c0-2.169 1.11-4.345 3.354-6.51 2.244-2.165 5.58-4.278 9.921-6.305C22.456 17.364 35.05 13.7 50.651 10.62 81.845 4.46 124.962.646 172.602.646c47.641 0 90.757 3.813 121.952 9.974 15.601 3.081 28.194 6.744 36.875 10.798 4.342 2.027 7.678 4.14 9.922 6.305 2.243 2.165 3.354 4.341 3.354 6.51Z"
        stroke="#E6E6E6"
        stroke-opacity=".2"
      />
    </>
  ),
});

export default EllipsisMob;
