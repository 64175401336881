import { createIcon } from "@chakra-ui/icons";

const VehicleIcon = createIcon({
  displayName: "VehicleIcon",
  viewBox: "0 0 40 39",
  path: (
    <>
      <path
        opacity=".2"
        d="M7.047 17.693c0-1.243.899-2.305 2.126-2.509l1.974-.33a2.544 2.544 0 0 0 1.598-.957l2.922-3.799a2.444 2.444 0 0 1 1.868-.969c4.988-.122 7.874 1.064 12.325 4.337.334.245.721.41 1.13.476 2.145.347 3.662.633 5.555 1.104 1.113.276 1.878 1.287 1.878 2.433v9.083a2.544 2.544 0 0 1-2.544 2.544H9.59a2.544 2.544 0 0 1-2.544-2.544v-8.869Z"
        fill="#FFAB91"
      />
      <path
        d="m16.785 19.926-2.21-4.42m13.06-.016c-4.648-3.217-7.592-4.436-12.53-3.897-1.007.11-1.597 1.16-1.231 2.104l.7 1.809m13.06-.016c3.893.396 5.975.552 8.285 2.364.363.284.557.728.557 1.189v2.892c0 .888-.72 1.608-1.608 1.608h-1.607m-5.626-8.053-13.06.015m18.686 8.038a2.813 2.813 0 1 1-5.626 0 2.813 2.813 0 0 1 5.626 0Zm-9.243-.402H13.57m0 0a2.813 2.813 0 1 1-5.626 0m5.626 0a2.813 2.813 0 1 0-5.626 0m2.411-10.062c-1.512.756-1.764 1.284-2.973 1.905-.159.082-.332.13-.51.15l-1.919.213c-.814.09-1.43.778-1.43 1.597v.973m4.42 5.224c-1.954-.126-2.719-.215-3.214-.401-.522-.266-1.206-.836-1.206-1.624v-3.199m0 0H4.73"
        stroke="#FF7145"
        strokeWidth="1.978"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default VehicleIcon;
