import { createIcon } from "@chakra-ui/icons";

const FooterBg2 = createIcon({
  displayName: "FooterBg2",
  viewBox: "0 0 623 394",
  path: (
    <>
      <g opacity=".7" filter="url(#footer-bg-2-a)">
        <path
          d="M276 144.564v94.872c0 15.052 9.178 28.95 24.059 36.482l93.876 47.442c14.893 7.52 33.237 7.52 48.13 0l93.876-47.442c14.894-7.52 24.059-21.43 24.059-36.482v-94.872c0-15.052-9.178-28.95-24.059-36.482L442.065 60.64c-14.893-7.52-33.237-7.52-48.13 0l-93.876 47.442c-14.894 7.52-24.059 21.43-24.059 36.482Z"
          fill="#7A32F1"
        />
      </g>
      <defs>
        <filter
          id="footer-bg-2-a"
          x=".911"
          y="-220.089"
          width="834.178"
          height="824.178"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation="137.544"
            result="effect1_foregroundBlur_1541_611"
          />
        </filter>
      </defs>
    </>
  ),
});

export default FooterBg2;
