import { Box, CenterProps, Stack, Text } from "@chakra-ui/react";
import { FC } from "react";

interface IProps extends CenterProps {
  data: any;
}

const KnowMoreCircle: FC<IProps> = ({ data, ...props }) => {
  return (
    <Stack
      background="#FFFFFF14"
      width={{ base: 100, md: 200 }}
      height={{ base: 100, md: 200 }}
      borderRadius="100%"
      padding={{ base: 15, md: 22 }}
      justifyContent="center"
      alignItems="center"
      gap={{ base: 0, md: 1 }}
      {...props}
    >
      <Box className="know-more-icon">{data.icon}</Box>
      <Text className="know-more-title" color="white">
        {data.title}
      </Text>
      <Text className="know-more-content">{data.content}</Text>
    </Stack>
  );
};

export default KnowMoreCircle;
