import { createIcon } from "@chakra-ui/icons";

const HomeHexa1 = createIcon({
  displayName: "HomeHexa1",
  viewBox: "0 0 447 493",
  path: (
    <>
      <path
        opacity=".4"
        d="M0 161.152v170.696c0 27.084 14.45 52.086 37.862 65.644l147.756 85.363a75.779 75.779 0 0 0 75.754 0l147.756-85.363C432.571 383.966 447 358.932 447 331.848V161.152c0-27.084-14.45-52.086-37.872-65.644L261.372 10.145a75.782 75.782 0 0 0-75.754 0L37.862 95.508C14.419 109.035 0 134.068 0 161.152Z"
        fill="url(#home-hexa-1-a)"
        fillOpacity=".2"
      />
      <defs>
        <linearGradient
          id="home-hexa-1-a"
          x1="-144.619"
          y1="-36.157"
          x2="764.848"
          y2="661.494"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#FDD2FD" />
          <stop offset=".23" stopColor="#E87AF5" />
          <stop offset=".37" stopColor="#9A30CE" />
          <stop offset=".46" stopColor="#7E26BE" />
          <stop offset=".55" stopColor="#651DB1" />
          <stop offset=".63" stopColor="#5015A5" />
          <stop offset=".75" stopColor="#390D98" />
          <stop offset=".86" stopColor="#310B94" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default HomeHexa1;
