import { createIcon } from "@chakra-ui/icons";

const Facebook = createIcon({
  displayName: "Facebook",
  viewBox: "0 0 22 22",
  path: (
    <>
      <g clip-path="url(#clip0_41_572)">
        <path
          d="M22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 5.49 4.023 10.041 9.281 10.866V14.18H6.488V11h2.793V8.577c0-2.757 1.643-4.28 4.155-4.28 1.203 0 2.462.215 2.462.215v2.707h-1.387c-1.366 0-1.792.848-1.792 1.718V11h3.05l-.487 3.18h-2.563v7.686C17.977 21.041 22 16.49 22 11Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_572">
          <path fill="currentColor" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export default Facebook;
