import { createIcon } from "@chakra-ui/icons";

const Court = createIcon({
  displayName: "Court",
  viewBox: "0 0 118 105",
  path: (
    <>
      <path
        d="M33.172 38.596 5.803 49.502l64.372-7.123L98.64 30.806l-65.467 7.79Z"
        fill="#33007D"
      />
      <path
        d="M26.15 45.79c-3.646-.71-11.647 1.18-15.191 2.215l3.906 35.666h7.162l10.851-3.101c-2.34-11.61-6.96-34.82-6.728-34.78Z"
        fill="#1BEBE7"
      />
      <path
        d="M16.012 83.108c-2.307.177-2.588 3.028-2.44 4.432h8.65c3.697-2.364 11-7.092 10.646-7.092-.444 0-14.86 1.552-16.856 2.66Z"
        fill="#88BFFF"
      />
      <path
        d="M71.925 40.794c-4.514-1.034-13.022.647-15.192 2.372l3.906 33.414h9.767l8.246-2.587-6.727-33.199ZM93.198 32.74c-3.69 0-14.324 1.093-15.192 2.186l3.906 35.196 9.55.655 8.463-3.716-6.727-34.32Z"
        fill="#1BEBE7"
      />
      <path
        d="M82.12 70.027c-1.226.351-1.533 2.924-1.533 4.166L91.54 75.29l11.611-2.85c-.292-1.682-1.314-5.132-3.067-5.483-2.19-.438-16.43 2.193-17.964 3.07Z"
        fill="#88BFFF"
      />
      <path
        d="M47.854 37.993c-5.209-.523-12.153 1.236-14.975 2.18l3.907 34.89 9.115.872 8.898-2.617-6.945-35.325Z"
        fill="#1BEBE7"
      />
      <path
        d="M36.245 75.302c-1.585.686-1.541 3.43-1.32 4.717l12.986.43 9.466-3.432c-.074-1.358-.749-4.117-2.862-4.289-2.641-.214-16.289 1.716-18.27 2.574Z"
        fill="#88BFFF"
      />
      <path
        d="M34.58 92.767c.144-.058.299-.099.452-.122 36.516-5.438 57.393-8.304 73.362-9.103 2.165-.108 2.745 2.672.737 3.49-9.81 3.994-15.04 5.539-22.005 7.21-44.713 6.084-69.18 10.029-77.25 10.058-1.43.005-1.445-1.064-.117-1.595l24.821-9.938Z"
        fill="#73298B"
      />
      <path
        d="M10.837 88.306c-2.631.876-1.827 3.722-1.097 5.036l98.454-17.952c.877-4.553-3.509-3.94-5.482-3.94-1.974 0-44.147 5.108-65.125 8.1-3.157.35-6.87 1.46-8.332 1.97-5.043 1.897-15.787 5.91-18.418 6.787Z"
        fill="#33007D"
      />
      <path
        d="m27.235 86.34-20.79 8.323 1.75 9.638 26.044-9.638c15.246-1.825 48.41-5.914 59.09-7.666 10.68-1.752 17.873-1.46 20.134-1.095-.364-1.972-1.181-6.528-1.532-8.98-.35-2.454-9.775-1.607-14.444-.877-14.663 1.826-45.915 5.695-53.619 6.571-7.703.876-14.298 2.848-16.633 3.724Z"
        fill="#9A30CE"
      />
      <path
        d="M101.184 20.088 61.823.506 32.576 20.088 27.079 35.32l38.042-3.046 33.204-5.875c.44-.072 1.627-.391 2.859-1.088 1.231-.696.513-3.771 0-5.222Z"
        fill="#1BEBE7"
      />
      <path
        d="M.641 43.82c.698 1.74 3.342 1.16 4.578.652l27.245-10.447c-2.615-1.219-2.252-3.99-1.743-5.224L61.89.506l-31.17 11.97C21.639 20.894 3.082 37.9 1.513 38.597c-1.962.87-1.744 3.047-.872 5.224Z"
        fill="#0DA09E"
      />
      <path
        d="m5.156 44.816.875 4.686 27.366-10.935 65.24-7.81-.657-4.464-65.459 7.81L5.156 44.816Z"
        fill="#9A30CE"
      />
      <path
        d="M72.207 16.746c0 5.393-3.958 9.548-8.581 9.548-4.624 0-8.791-4.155-8.791-9.548-.418-5.425 2.93-10.2 8.371-9.765 4.624 0 9 4.372 9 9.765Z"
        fill="#5015A5"
      />
      <path
        d="M74.143 16.1c0 5.394-4.104 9.549-8.898 9.549-4.795 0-9.117-4.155-9.117-9.548-.433-5.425 3.039-10.2 8.682-9.765 4.794 0 9.333 4.372 9.333 9.765Z"
        fill="#9A30CE"
      />
      <path
        d="M70.271 15.846c0 4.138-2.778 6.579-5.679 6.579-2.9 0-5.908-2.847-5.908-6.437-.262-3.612 2.495-6.727 5.91-6.438 2.9 0 5.677 2.706 5.677 6.296Z"
        fill="#5B0F80"
      />
    </>
  ),
});

export default Court;
