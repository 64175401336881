import { createIcon } from "@chakra-ui/icons";

const FooterBg1 = createIcon({
  displayName: "FooterBg1",
  viewBox: "0 0 951 394",
  path: (
    <>
      <g opacity=".6" filter="url(#footer-bg-1-a)">
        <path
          d="m50.021 327.282 65.697 137.79c10.424 21.862 32.534 36.094 57.991 37.382l160.556 8.016c25.467 1.263 50.421-10.634 65.473-31.217l94.85-129.791c15.053-20.582 17.888-46.728 7.465-68.59l-65.697-137.79c-10.423-21.862-32.533-36.094-57.991-37.382L217.81 97.683c-25.468-1.262-50.421 10.635-65.474 31.217l-94.85 129.792c-15.052 20.582-17.888 46.728-7.465 68.59Z"
          fill="#CB5EE6"
        />
      </g>
      <defs>
        <filter
          id="footer-bg-1-a"
          x="-398.813"
          y="-345.032"
          width="1349.7"
          height="1298.22"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation="221.312"
            result="effect1_foregroundBlur_1541_612"
          />
        </filter>
      </defs>
    </>
  ),
});

export default FooterBg1;
