import React, { useEffect } from "react";

import {
  Box,
  Center,
  HStack,
  ResponsiveValue,
  Stack,
  Text,
} from "@chakra-ui/react";
import { foldData } from "../components/Feature/staticData";

export default function Feature() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="feature-page">
      <Box position="relative" overflow="hidden">
        <Box
          className="feature-page-bg"
          width="100%"
          height="100vh"
          position="fixed"
        />
        <Center
          background="#080F16"
          height="850px"
          width={{ base: "150%", md: "120%", lg: "120%" }}
          transform={{
            base: "translate(-17%, -27%)",
            md: "translate(-9%, -47%)",
          }}
          borderRadius="50%"
          alignItems="flex-end"
          padding={{ base: "30% 35%", md: "9% 25%" }}
        >
          <Stack
            alignItems="center"
            textAlign="center"
            gap={{ base: 10, md: 8 }}
          >
            <Text
              fontSize={{ base: "36px", md: "60px" }}
              sx={{ fontWeight: "bold" }}
              color="white"
            >
              <Text as="span" color="#1BEBE7">
                Gift financial security
              </Text>{" "}
              to your family
            </Text>
            <Text color="#CDCAD7" fontSize={{ base: "16px", md: "22px" }}>
              At WALLT, we have curated features that aid in your journey of
              planning your legacy.
            </Text>
          </Stack>
        </Center>
      </Box>
      <Stack gap={{ base: 10, md: 5 }} mt={{ base: "-57%", md: "-30%" }}>
        {foldData.map((item) => (
          <HStack
            flexDirection={{
              base: "column",
              md: item.direction as ResponsiveValue<any>,
            }}
            p="10px 40px"
          >
            <Center flex={1} width={{ base: "auto", md: "50%" }}>
              {item.icon}
            </Center>
            <Center flex={1}>
              <Stack
                gap={10}
                width="370px"
                textAlign={{ base: "center", md: "left" }}
              >
                <Text fontSize="36px" color="white">
                  {item.title}
                </Text>
                <Text fontSize="24px" color="#9089A8">
                  {item.content}
                </Text>
              </Stack>
            </Center>
          </HStack>
        ))}
      </Stack>
    </Box>
  );
}
