import { createIcon } from "@chakra-ui/icons";

const HealthIcon = createIcon({
  displayName: "HealthIcon",
  viewBox: "0 0 40 40",
  path: (
    <>
      <path
        opacity=".2"
        d="M22.931 12.713c-1.627-3.2-6.828-6.37-12.427-1.97-6.795 5.338-.775 18.443 11.303 23.628a2.85 2.85 0 0 0 2.248 0c12.078-5.186 18.098-18.29 11.303-23.629-5.6-4.4-10.8-1.228-12.427 1.971Z"
        fill="#F48FB1"
      />
      <path
        d="M4.427 15.96c-1.206-9.643 8.84-14.063 15.27-6.832 4.421-5.222 11.253-4.419 14.467 1.206M8.847 23.997c4.062 3.91 6.347 5.416 9.685 7.07.744.37 1.61.432 2.371.1 4.747-2.067 11.038-7.423 13.261-14.002M2.015 19.579h8.44l2.41-3.215 2.412 5.627 4.018-7.234 3.215 8.037 2.411-3.215h2.813"
        stroke="#FF3B7D"
        strokeWidth="2.411"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default HealthIcon;
