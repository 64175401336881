import { createIcon } from "@chakra-ui/icons";

const MaleAvatar = createIcon({
  displayName: "MaleAvatar",
  viewBox: "0 0 93 93",
  path: (
    <>
      <path
        d="M46.044 90.866c24.893 0 45.072-20.18 45.072-45.072 0-24.892-20.18-45.071-45.072-45.071C21.152.723.973 20.902.973 45.794c0 24.893 20.179 45.072 45.071 45.072Z"
        fill="#364147"
      />
      <path
        d="M45.7 64.782c11.035 0 19.98-8.945 19.98-19.98 0-11.036-8.945-19.982-19.98-19.982s-19.981 8.946-19.981 19.981c0 11.036 8.946 19.981 19.98 19.981Z"
        fill="#333"
      />
      <path
        opacity=".1"
        d="M38.68 62.923H52.62v11.153a6.969 6.969 0 1 1-13.937 0V62.923h-.004Z"
        fill="#000"
      />
      <path
        d="M40.007 62.406h11.28a1.331 1.331 0 0 1 1.33 1.331v9.822a6.969 6.969 0 1 1-13.937 0v-9.822a1.331 1.331 0 0 1 1.327-1.33Z"
        fill="#BE7C5E"
      />
      <path
        opacity=".1"
        d="M38.707 68.187a20.065 20.065 0 0 0 13.942.04v-1.712H38.707v1.672Z"
        fill="#000"
      />
      <path
        d="M45.7 69.12c11.035 0 19.98-8.946 19.98-19.98 0-11.036-8.945-19.982-19.98-19.982S25.719 38.104 25.719 49.14c0 11.035 8.946 19.981 19.98 19.981Z"
        fill="#BE7C5E"
      />
      <path
        d="M33.591 40.9c.234-.863.528-1.813 1.303-2.261.612-.358 1.37-.302 2.078-.267 5.523.302 10.998-.957 16.37-2.272 3.07-.751 6.357-1.665 8.343-4.14 1.987-2.476-.604-10.255-3.627-11.173-1.987-.608-4.291.525-6.175-.365-.838-.398-1.454-1.14-2.177-1.72-1.482-1.193-3.385-1.674-5.229-2.126-2.153-.533-4.481-1.053-6.527-.2-1.59.66-2.75 2.055-4.256 2.881-2.864 1.59-6.357.85-9.639.843-1.108 0-2.431.222-2.872 1.24-.298.69-.06 1.477-.028 2.228.095 2.384-1.871 4.24-3.52 5.96-1.65 1.72-3.207 4.176-2.166 6.314.398.814 1.125 1.43 1.538 2.236.711 1.399.354 3.087.612 4.633a11.655 11.655 0 0 0 1.3 3.337c1.223 2.384 7.37 15.218 10.194 11.956 1.367-1.59 1.59-6.182 2.142-8.248.763-2.97 1.541-5.922 2.336-8.857Z"
        fill="#333"
      />
      <path
        d="M31.484 51.897c1.027 0 1.86-1.56 1.86-3.484 0-1.925-.833-3.485-1.86-3.485-1.027 0-1.859 1.56-1.859 3.485 0 1.924.832 3.484 1.86 3.484Z"
        fill="#BE7C5E"
      />
      <path
        d="M46.13 90.819c24.892 0 45.072-20.18 45.072-45.072C91.202 20.855 71.022.676 46.13.676 21.238.676 1.06 20.855 1.06 45.747c0 24.893 20.179 45.072 45.071 45.072Z"
        fill="#080F16"
      />
      <path
        d="M72.675 80.966C56.833 93.099 34.735 93.002 19 80.73l11.532-5.377a11.11 11.11 0 0 0 2.024-1.226 10.941 10.941 0 0 0 3.03-3.557c.56-1.067.931-2.221 1.099-3.413.116-.774.174-1.554.175-2.336 0-.816-.021-1.632-.05-2.447a89.992 89.992 0 0 1-.038-1.6c-.009-.747-.017-1.495-.023-2.243l.474.236 1.232.634.571.097 6.608 1.117 7.375 1.25.223 7.543.074 2.466.035 1.197v.073l.237.1 2.624 1.06 16.473 6.662Z"
        fill="#F2A196"
      />
      <path
        d="M53.34 73.144c-.877.102-2.37-.235-3.195-.681-3.08-1.692-4.619-3.064-6.252-4.262-1.943-1.428-7.062-6.011-7.062-6.011-.017-.533-.05-.886-.057-1.414-.01-.747-.018-1.494-.024-2.242l.474.235 1.232.635.572.096 6.607 1.118 7.376 1.249.222 7.543.074 2.466.035 1.198v.07h-.002Z"
        fill="#E88870"
      />
      <path
        d="M68.43 33.754a6.02 6.02 0 0 1-.079.412 7.538 7.538 0 0 1-.784 2.002l-.019.033c-.135.235-.28.471-.436.707a17.536 17.536 0 0 1-1.356 1.733l-.291.322c-.197.22-.396.434-.595.646 1.055-.235 2.076.743 2.263 1.799.188 1.056-.237 2.121-.76 3.064-.918 1.67-2.24 2.78-3.404 4.23-.872 1.08-1.355 2.357-2.306 3.39l-.07.077c-2.51 2.621-6.473 3.234-9.772 4.354-1.185.405-14.035 4.036-14.035 4.828 0-.172-1.259-1.415-2.844-2.926-5.688-5.372-7.558-13.672-4.174-20.791 3.638-7.661 11.9-13.128 19.768-15.754 2.46-.82 5.252-1.372 7.584-.236 2.332 1.137 3.494 4.66 1.56 6.382a6.224 6.224 0 0 1 4.249-1.229 5.812 5.812 0 0 1 3.726 1.667 6.358 6.358 0 0 1 1.647 2.848 5.85 5.85 0 0 1 .128 2.442Z"
        fill="#48352C"
      />
      <path
        d="M68.433 33.754c-.021.14-.047.276-.078.413-.021.04-.043.082-.064.13-.268.607-.39 1.263-.71 1.87l-.02.034c-.135.236-.28.471-.436.707a15.825 15.825 0 0 1-1.356 1.733l-.3.323c-.197.219-.396.433-.595.646 1.054-.236 2.076.742 2.263 1.798.187 1.056-.237 2.122-.76 3.065-.918 1.669-2.24 2.779-3.404 4.229-.872 1.08-1.356 2.357-2.306 3.39a8.864 8.864 0 0 1-2.024-4.59c-.389-3.126-.152-6.532-.258-9.696-.067-1.99-.711-2.357.488-3.967a17.568 17.568 0 0 1 3.318-3.133c.967-.634 2.211-.733 3.365-.57a5.583 5.583 0 0 1 2.745 1.178 5.85 5.85 0 0 1 .132 2.44Z"
        fill="#554036"
      />
      <ellipse cx="34.416" cy="51.928" rx="4.029" ry="4.007" fill="#F2A196" />
      <ellipse cx="35.693" cy="51.929" rx="3.607" ry="3.588" fill="#E88870" />
      <path
        d="m35.746 48.157 1.038 12.965L52.53 70.76a4.696 4.696 0 0 0 5.85-2.329 62.844 62.844 0 0 0 3.114-7.515c.625-1.848 1.237-3.746 1.296-5.712.06-1.966-.502-4.007-1.185-5.822-.071-.191-.45-.778-.417-.979l.787-4.7c.298-1.77.68-3.619.237-5.398-.505-2.042-2.055-2.912-3.33-4.437-3.356 4.3-8.873 6.73-13.691 9.194-2.477 1.273-9.445 5.096-9.445 5.096Z"
        fill="#F2A196"
      />
      <path
        d="M61.932 59.59c-1.021 3.988-2.152 8.097-2.993 9.572-.63 1.101-1.51 1.313-2.9 1.65a12.25 12.25 0 0 1-10.023-2.107l-9.265-7.42c-.142-1.39-.237-1.601-.305-2.997-.034-.632-.237-1.263-.086-1.886.042.327.182.634.403.88 1.121 1.513 2.43 3.222 4.292 3.844 1.944.649 4.155.75 6.032-.165 1.633-.796 3.232-1.836 4.936-2.491a8.346 8.346 0 0 1 3.581-.616c1.74.118 2.484.899 3.757 1.929.417.34.884.693 1.422.707.607-.012.637-.512 1.15-.9Z"
        fill="#48352C"
      />
      <path
        d="M47.389 47.638c1.446 0 1.446 2.211 0 2.223h-.067c-1.445 0-1.445-2.211 0-2.223h.067ZM59.268 47.31c1.344.012 1.344 2.051 0 2.063h-.059c-1.341 0-1.341-2.05 0-2.063h.06Z"
        fill="#00214E"
      />
      <path
        d="M53.41 47c-.02.082 3.166 8.517 3.166 8.517l-4.252.205M43.344 45.368c2.194-.45 4.45-.513 6.666-.186M57.336 45.394a13.183 13.183 0 0 1 4.136-.285"
        stroke="#00214E"
        stroke-width=".5"
      />
      <path
        d="M52.084 60.18a6.699 6.699 0 0 0 4.124.075c.145-.045.083-.274-.061-.236a6.472 6.472 0 0 1-3.999-.073c-.144-.05-.206.177-.064.236v-.003Z"
        fill="#F2A196"
      />
      <path
        d="M36 58.25 35.5 48l2.5-1.5c-.981 3.505-1.77 11.756 2.737 15.303l.263.198a7.009 7.009 0 0 1-.263-.198L36 58.251Z"
        fill="#48352C"
      />
      <path
        d="M73.23 80.57c-16.02 12.493-38.528 12.59-54.668.236l11.694-5.585c.557-.265 1.091-.576 1.596-.93.154-.107.308-.229.454-.336 5.725 3.972 13.504 6.45 20.185 3.389 1.591-.726 3.049-1.908 3.902-3.407L73.23 80.57Z"
        fill="url(#paint0_linear_41_1765)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_41_1765"
          x1="22.302"
          y1="90.536"
          x2="68.091"
          y2="70.136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1FCECB" />
          <stop offset="1" stop-color="#6878B1" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default MaleAvatar;
