import { createIcon } from "@chakra-ui/icons";

const ForwardArrow = createIcon({
  displayName: "Forward Arrow",
  viewBox: "0 0 80 38",
  path: (
    <>
      <path
        d="M78.768 20.768a2.5 2.5 0 0 0 0-3.536l-15.91-15.91a2.5 2.5 0 0 0-3.536 3.536L73.464 19 59.323 33.142a2.5 2.5 0 0 0 3.536 3.536l15.91-15.91ZM0 21.5h77v-5H0v5Z"
        fill="currentColor"
      />
    </>
  ),
});

export default ForwardArrow;
