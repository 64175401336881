import { createIcon } from "@chakra-ui/icons";

const InsuranceIcon = createIcon({
  displayName: "InsuranceIcon",
  viewBox: "0 0 40 39",
  path: (
    <>
      <rect
        opacity=".2"
        x="4"
        y="12"
        width="32"
        height="17"
        rx="2.245"
        fill="#FFCDE8"
      />
      <path
        d="M39.917 5.616a.916.916 0 0 0-.918-.846.88.88 0 0 0-.844.92l.004 4.387a.886.886 0 0 0 .882.885.88.88 0 0 0 .882-.885V5.9c0-.095 0-.19-.006-.284ZM30.353 9.192H5.263c-.427 0-.836.207-1.138.576a2.207 2.207 0 0 0-.471 1.39V21.38c0 .522.17 1.022.47 1.39.303.37.712.576 1.138.576h25.091c.427 0 .836-.207 1.137-.576.302-.368.472-.868.472-1.39V11.158c0-.521-.17-1.021-.472-1.39-.301-.369-.71-.576-1.137-.576Zm.322 12.188a.441.441 0 0 1-.094.278.295.295 0 0 1-.228.116H5.263a.295.295 0 0 1-.228-.116.441.441 0 0 1-.094-.278V11.158c0-.104.033-.204.094-.278a.295.295 0 0 1 .227-.115h25.091c.085 0 .167.041.228.115.06.074.094.174.094.278V21.38Z"
        fill="#FF4AAC"
      />
      <path
        d="M18.516 16.063v-1.327h1.297V13.44h-1.297v-.649h-1.297v.678a1.858 1.858 0 0 0-1.622 1.917 1.893 1.893 0 0 0 1.621 1.912v1.331h-1.297v1.298h1.297v.649h1.298v-.683a1.894 1.894 0 0 0 1.622-1.912 1.858 1.858 0 0 0-1.622-1.917Zm-1.622-.678a.571.571 0 0 1 .325-.584v1.168a.617.617 0 0 1-.325-.584Zm1.622 3.179v-1.168a.563.563 0 0 1 .324.573.622.622 0 0 1-.324.595ZM34.71 3H18.448a.59.59 0 0 0-.452.227.864.864 0 0 0-.187.547c0 .205.067.402.187.547a.59.59 0 0 0 .452.227h16.264c.339 0 .664.163.904.454.24.29.375.684.375 1.094v7.63c0 .205.067.402.187.547a.59.59 0 0 0 .453.227.59.59 0 0 0 .452-.227.863.863 0 0 0 .187-.547v-7.63c0-.821-.27-1.609-.75-2.19C36.04 3.327 35.39 3 34.712 3Z"
        fill="#FF4AAC"
      />
      <path
        d="M36.622 16.69a.601.601 0 0 0-.458.225.843.843 0 0 0-.19.542v2.41c0 .406-.137.796-.38 1.084-.242.287-.572.449-.915.449V9.133c0-.813-.273-1.593-.76-2.168-.485-.575-1.144-.898-1.831-.898H4.886c0-.407.136-.797.38-1.085.242-.287.572-.449.915-.449h8.79c.172 0 .337-.08.458-.224a.843.843 0 0 0 .19-.542.843.843 0 0 0-.19-.542.601.601 0 0 0-.458-.225h-8.79c-.687 0-1.346.323-1.832.898-.485.575-.758 1.355-.758 2.169-.687 0-1.346.323-1.832.898S1 8.32 1 9.133v13.8c0 .814.273 1.594.759 2.169S2.904 26 3.59 26h28.497c.687 0 1.346-.323 1.832-.898s.759-1.355.759-2.169c.687 0 1.346-.323 1.831-.898.486-.575.76-1.355.76-2.168v-2.41a.843.843 0 0 0-.19-.542.601.601 0 0 0-.458-.225Zm-3.239 6.243c0 .407-.136.797-.38 1.085-.242.287-.572.449-.915.449H3.59c-.344 0-.673-.162-.916-.45a1.687 1.687 0 0 1-.38-1.084v-13.8c0-.406.137-.796.38-1.084.243-.287.572-.449.916-.449h28.497c.343 0 .673.162.916.45.243.287.38.677.38 1.083v13.8Z"
        fill="#FF4AAC"
      />
    </>
  ),
});

export default InsuranceIcon;
