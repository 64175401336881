import { createIcon } from "@chakra-ui/icons";

const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  viewBox: "0 0 133 120",
  path: (
    <>
      <path
        d="m18.114 21.4-1.771-6.338c-4.015.704-12.121 2.062-12.4 2.112-3.897.704-3.188 4.46-2.48 6.69L23.428 114c.567 5.351 4.251 5.516 6.023 4.929l11.69-2.113-4.25-.352c-2.551.563-3.898-1.878-4.252-3.169L22.011 21.399h-3.897Z"
        fill="#C8E8FE"
        stroke="#D9D9D9"
      />
      <path
        d="M17.953 21.45h4.669c0-7.159-.934-21.259-4.669-20.4-4.268 1.432-1.778 14.196 0 20.4Z"
        fill="#8DFCFA"
      />
      <path
        d="M24.782.373c-8.153 0-7.942.926-7.59 1.277 1.967-.843 2.085 3.173 2.671 5.398 3.983 34.545 12.019 103.916 12.3 105.04 2.53 7.026 4.1 2.927 4.568 0V95.576h72.745c-3.28-26.816-9.91-81.924-10.192-87.826-.28-5.902-3.162-7.377-4.568-7.377H24.782Z"
        fill="#16BCB9"
      />
      <path
        d="M36.858 110.699c-.281 4.798-1.52 5.527-2.105 5.291 2.105 1.411 74.365 1.411 78.224 1.059 3.087-.283 3.625-3.175 3.508-4.586l-.31-14.974a2.001 2.001 0 0 0-2-1.958H36.858c.117 3.057.28 10.371 0 15.168Z"
        fill="#8DFCFA"
      />
      <rect
        x="29.645"
        y="11.23"
        width="31.294"
        height="4.471"
        rx="2.235"
        fill="#D9D9D9"
      />
      <rect
        x="30.675"
        y="25.56"
        width="35.873"
        height="5.343"
        rx="2.671"
        fill="#D9D9D9"
      />
      <rect
        x="34.118"
        y="55.935"
        width="44.066"
        height="5.748"
        rx="2.874"
        fill="#D9D9D9"
      />
      <rect
        x="36.033"
        y="69.985"
        width="24.907"
        height="5.748"
        rx="2.874"
        fill="#D9D9D9"
      />
      <rect
        x="32.835"
        y="40.608"
        width="65.78"
        height="6.386"
        rx="3.193"
        fill="#D9D9D9"
      />
      <rect
        x="71.891"
        y="25.28"
        width="20.437"
        height="5.748"
        rx="2.874"
        fill="#D9D9D9"
      />
      <path
        d="m128.668 95.725-22.552-15.171-1.697-.849 2.545-5.515 24.342 17.74c.44.321.747.807.765 1.351.047 1.463-.465 2.149-1.731 2.664-.552.225-1.177.113-1.672-.22Z"
        fill="#401970"
      />
      <path
        d="m91.44 54.36-9.334 19.092 21.637 11.454 8.485-18.667-1.273-6.363-6.364-3.818L91.44 54.36Z"
        fill="#AC4CCC"
      />
      <path
        d="M94.54 56.2c-3.733-.678-4.8.847-4.8 1.979-.99-1.98-1.473-6.574 2.6-7.253 14.424-1.273 22.43 9.374 23.278 13.192.679 3.055-2.545 4.384-4.242 4.667 1.697-7.637-12.169-11.736-16.835-12.584ZM105.004 88.615c-2.109 2.97-9.294 1.391-16.533-2.544-8.064-3.82-12.445-8.757-10.185-11.879 2.121-1.697 6.78-2.07 15.265 2.542 10.193 4.67 12.936 9.153 11.453 11.881Z"
        fill="#7D28B3"
      />
    </>
  ),
});

export default DocumentIcon;
