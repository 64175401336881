import { FC } from "react";

import { Input, InputProps } from "@chakra-ui/react";

const StyledInput: FC<InputProps> = (props) => {
  return (
    <Input
      background="#FFFFFF0D"
      border="1px solid #FFFFFF33"
      color="#FFFFFF99"
      fontSize="15px"
      padding="12px 14px"
      height="auto"
      {...props}
    />
  );
};

export default StyledInput;
