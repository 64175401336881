import { createIcon } from "@chakra-ui/icons";

const VaultShadow = createIcon({
  displayName: "Vault Shadow",
  viewBox: "0 0 989 383",
  path: (
    <>
      <g opacity=".9">
        <ellipse
          cx="494.263"
          cy="191.5"
          rx="494.263"
          ry="191.5"
          fill="url(#paint0_radial_41_646)"
          fill-opacity=".1"
        />
        <ellipse
          cx="495.795"
          cy="153.103"
          rx="282.436"
          ry="110.331"
          fill="url(#paint1_radial_41_646)"
          fill-opacity=".8"
        />
        <ellipse
          cx="499.078"
          cy="149.391"
          rx="321.485"
          ry="125.678"
          fill="url(#paint2_radial_41_646)"
          fill-opacity=".8"
        />
        <ellipse
          cx="491.587"
          cy="142.277"
          rx="328.973"
          ry="128.727"
          fill="url(#paint3_radial_41_646)"
          fill-opacity=".53"
        />
        <ellipse
          cx="499.076"
          cy="150.069"
          rx="221.99"
          ry="86.383"
          fill="url(#paint4_linear_41_646)"
          fill-opacity=".21"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_41_646"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-27.62948 219.6904 -561.73396 -70.64678 494.263 191.5)"
        >
          <stop stop-color="#00D1FF" />
          <stop offset=".868" stop-color="#00D1FF" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_41_646"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-12.2319 110.44874 -280.68197 -31.08477 495.795 153.103)"
        >
          <stop stop-color="#E87AF5" />
          <stop offset=".493" stop-color="#1569A5" stop-opacity=".18" />
          <stop offset=".975" stop-color="#1569A5" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_41_646"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-8.24325 152.54744 -389.5317 -21.04923 499.078 149.391)"
        >
          <stop stop-color="#00F7F2" />
          <stop offset=".47" stop-color="#fff" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_41_646"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 135.463 -346.187 0 491.587 142.277)"
        >
          <stop stop-color="#7A32F1" />
          <stop offset=".655" stop-color="#323AF1" stop-opacity=".26" />
          <stop offset=".981" stop-color="#323AF1" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_41_646"
          x1="499.076"
          y1="63.687"
          x2="431.206"
          y2="263.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8850FF" />
          <stop offset="1" stop-color="#523099" stop-opacity="0" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default VaultShadow;
