import { FC } from "react";

import { Textarea, TextareaProps } from "@chakra-ui/react";

const StyledTextArea: FC<TextareaProps> = (props) => {
  return (
    <Textarea
      background="#FFFFFF0D"
      border="1px solid #FFFFFF33"
      color="#FFFFFF99"
      fontSize="15px"
      padding="12px 14px"
      {...props}
    />
  );
};

export default StyledTextArea;
