import { createIcon } from "@chakra-ui/icons";

const Twitter = createIcon({
  displayName: "Twitter",
  viewBox: "0 0 22 22",
  path: (
    <>
      <g clip-path="url(#clip0_41_573)">
        <path
          d="M6.921 19.938c8.3 0 12.84-6.878 12.84-12.841 0-.194-.003-.391-.012-.585A9.168 9.168 0 0 0 22 4.176a8.9 8.9 0 0 1-2.59.709 4.538 4.538 0 0 0 1.984-2.496 9.072 9.072 0 0 1-2.865 1.095 4.52 4.52 0 0 0-7.694 4.115 12.82 12.82 0 0 1-9.301-4.712A4.519 4.519 0 0 0 2.93 8.91a4.52 4.52 0 0 1-2.045-.563v.056a4.512 4.512 0 0 0 3.622 4.426 4.486 4.486 0 0 1-2.037.077 4.522 4.522 0 0 0 4.215 3.137A9.052 9.052 0 0 1 0 17.91a12.804 12.804 0 0 0 6.921 2.027Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_573">
          <path fill="currentColor" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </>
  ),
});

export default Twitter;
