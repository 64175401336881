import { createIcon } from "@chakra-ui/icons";

const Vault = createIcon({
  displayName: "Vault",
  viewBox: "0 0 317 433",
  path: (
    <>
      <path
        d="M18.6947 351.34V340.055L45.6796 355.756V368.022L18.6947 351.34Z"
        fill="#88BFFF"
      />
      <path
        d="M131.052 415.613V404.328L158.037 420.029V432.295L131.052 415.613Z"
        fill="#88BFFF"
      />
      <path
        d="M308.172 342.508L280.697 358.699V369.984L308.172 355.265V342.508Z"
        fill="#88BFFF"
      />
      <path
        d="M55.4939 361.643L45.6812 368.022V355.756L55.4939 361.643Z"
        fill="#C8E8FE"
      />
      <path
        d="M167.359 425.917L157.546 432.295V420.029L167.359 425.917Z"
        fill="#C8E8FE"
      />
      <path
        d="M280.938 369.652L270.391 364.579L280.873 358.208L280.938 369.652Z"
        fill="#C8E8FE"
      />
      <path
        opacity="0.9"
        d="M301.394 81.9821L189.161 20.6932C165.506 7.77602 136.938 7.63735 113.16 20.3243L9.95654 75.3879L151.26 157.54C177.754 172.259 174.319 190.412 174.319 204.15V421.992L313.66 339.075C313.987 272.676 314.837 134.873 315.622 114.855C316.407 94.8367 306.464 84.5988 301.394 81.9821Z"
        fill="url(#vault-a)"
      />
      <path
        opacity="0.56"
        d="M10.9391 76.0939C2.30393 74.9164 0.799318 86.0702 1.12641 91.7942C0.962739 164.408 0.635401 310.029 0.635401 311.599C-0.345778 325.827 7.01366 333.187 11.4294 336.13L166.961 426.407L174.32 422.482V202.187C177.175 175.202 163.348 164.899 154.606 159.502L82.9731 117.798L10.9391 76.0939Z"
        fill="url(#vault-b)"
        stroke="#1BEBE7"
      />
      <path
        d="M152.173 180.632L27.248 109.311C23.9148 107.408 19.769 109.815 19.769 113.654V317.018C19.769 318.823 20.7417 320.488 22.314 321.374L147.239 391.786C150.572 393.665 154.694 391.257 154.694 387.43V184.974C154.694 183.179 153.732 181.522 152.173 180.632Z"
        stroke="#1BEBE7"
      />
      <path
        d="M144.053 246.887C143.19 243.82 141.721 242.648 140.207 242.45C137.29 242.07 135.96 245.675 135.96 248.617V260.256C135.96 261.496 136.157 262.745 136.729 263.845C137.81 265.924 139.012 266.982 140.159 267.417C142.81 268.422 144.301 264.929 144.301 262.094V248.945C144.301 248.253 144.24 247.553 144.053 246.887Z"
        fill="#2E0640"
      />
      <ellipse
        cx="81.2512"
        cy="187.468"
        rx="34.0716"
        ry="21.3961"
        transform="rotate(57.5583 81.2512 187.468)"
        fill="#835BAE"
      />
      <path
        d="M66.2781 166.906L66.017 167.029C59.974 169.872 56.8478 176.647 58.6051 183.09C59.1291 185.012 60.0641 186.796 61.3456 188.321L78.995 209.317C82.0179 212.914 87.07 214.049 91.3408 212.091C93.291 211.197 94.9863 209.749 95.7947 207.762C102.272 191.84 89.9663 171.07 74.1145 166.05C71.5193 165.228 68.7412 165.747 66.2781 166.906Z"
        fill="#B97DCB"
      />
      <path
        d="M82.5374 216.382C79.1869 218.511 74.5923 218.156 69.7897 215.674C64.9998 213.2 60.0994 208.65 56.2523 202.598C52.4053 196.546 50.3662 190.178 50.1586 184.79C49.9504 179.389 51.5788 175.078 54.9292 172.948C58.2797 170.818 62.8743 171.174 67.677 173.655C72.4669 176.13 77.3673 180.679 81.2143 186.731C85.0614 192.783 87.1005 199.152 87.3081 204.539C87.5163 209.941 85.8879 214.252 82.5374 216.382Z"
        fill="#401970"
        stroke="#1BEBE7"
      />
      <path
        d="M68.3649 194.597C68.3649 194.597 68.366 194.596 68.3666 194.596C68.4395 194.522 68.5004 194.437 68.5459 194.344C68.547 194.342 68.5476 194.341 68.5483 194.338L68.3649 194.597ZM68.6752 185.096C68.4828 184.965 68.2811 184.943 68.2342 184.939C68.0639 184.923 67.8898 184.954 67.7283 185.033L61.4833 188.252C60.7238 189.316 59.9644 190.381 59.2043 191.446L59.2134 191.444L59.2106 191.448L58.8435 197.892C58.824 198.234 58.9825 198.552 59.2682 198.742C59.5529 198.931 59.9062 198.956 60.2139 198.807L60.2155 198.806L62.2203 197.828L62.4596 193.625L64.9294 190.004L65.0539 189.944L68.9808 188.027L69.0993 185.947C69.0985 185.886 69.0777 185.37 68.6751 185.097L68.6752 185.096Z"
        fill="#1BEBE7"
      />
      <path
        d="M61.622 190.269C61.5812 190.613 61.3584 190.906 61.0395 191.037L59.2144 191.443L59.2053 191.445C59.9642 190.38 60.7232 189.315 61.4827 188.25C61.5295 188.923 61.5757 189.596 61.6225 190.269L61.622 190.269Z"
        fill="#0DA09E"
      />
      <path
        d="M72.8861 188.309L72.8849 188.311L72.7239 191.137C71.4146 191.776 70.1053 192.415 68.7966 193.053C67.9817 194.199 67.1674 195.345 66.3525 196.491L66.1299 200.13L64.0017 201.169L64.0002 201.169C63.6924 201.318 63.3391 201.294 63.0544 201.105C62.7692 200.915 62.6102 200.597 62.6297 200.255L62.9968 193.811L63.0014 193.804L62.9896 193.807C63.7583 192.682 64.5265 191.557 65.2953 190.432C67.3788 189.415 69.4624 188.398 71.546 187.381C71.8465 187.248 72.1858 187.276 72.4614 187.458C72.7466 187.648 72.9056 187.966 72.8861 188.308L72.8861 188.309Z"
        fill="#1BEBE7"
      />
      <path
        d="M76.7266 191.541L76.7254 191.542L76.2678 199.576C76.2482 199.92 76.0436 200.227 75.7336 200.379L67.8679 204.219L67.8636 204.221C67.5565 204.369 67.2037 204.344 66.9196 204.155C66.6343 203.965 66.4759 203.648 66.4954 203.305L66.8877 196.687L66.883 196.687L66.8881 196.68L66.8882 196.678C67.6456 195.613 68.403 194.547 69.1604 193.482C71.2228 192.475 73.2846 191.47 75.347 190.463L75.3555 190.462L75.3872 190.443C75.6937 190.296 76.0438 190.321 76.327 190.51C76.6122 190.699 76.7455 191.199 76.7261 191.541L76.7266 191.541Z"
        fill="#1BEBE7"
      />
      <path
        d="M66.885 196.687L66.8897 196.687L66.8902 196.68L66.885 196.687ZM69.1518 193.493L68.9282 193.808L68.5523 194.338L68.3689 194.598L68.3667 194.6L67.9758 195.151L68.3706 194.596L68.5499 194.344L68.9281 193.811L69.1528 193.494L69.1518 193.492L69.1518 193.493ZM67.9742 195.152L67.8713 195.297L67.9747 195.152L67.9742 195.152Z"
        fill="#1BEBE7"
      />
      <path
        d="M65.3448 192.464C65.3046 192.808 65.0817 193.102 64.7623 193.233L63.0017 193.805L62.9899 193.808C63.7581 192.683 64.5269 191.557 65.2951 190.432C65.3115 191.11 65.3279 191.787 65.3443 192.464L65.3448 192.464Z"
        fill="#0DA09E"
      />
      <path
        d="M69.3414 196.175C69.1374 196.452 68.7637 196.507 68.4214 196.54L66.8891 196.687L66.8843 196.687L66.8894 196.68C67.6464 195.614 68.4033 194.547 69.1603 193.48C69.174 193.584 69.1876 193.687 69.2013 193.791L69.4403 195.237C69.4939 195.559 69.5355 195.913 69.3419 196.175L69.3414 196.175Z"
        fill="#0DA09E"
      />
      <defs>
        <linearGradient
          id="vault-a"
          x1="-88.357"
          y1="-31.9178"
          x2="608.048"
          y2="397.583"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stop-color="#FDD2FD" />
          <stop offset="0.23" stop-color="#E87AF5" />
          <stop offset="0.37" stop-color="#9A30CE" />
          <stop offset="0.46" stop-color="#7E26BE" />
          <stop offset="0.55" stop-color="#651DB1" />
          <stop offset="0.63" stop-color="#5015A5" />
          <stop offset="0.75" stop-color="#390D98" />
          <stop offset="0.82" stop-color="#310B94" />
        </linearGradient>
        <linearGradient
          id="vault-b"
          x1="-55.4604"
          y1="49.5082"
          x2="409.008"
          y2="246.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stop-color="#FDD2FD" />
          <stop offset="0.23" stop-color="#E87AF5" />
          <stop offset="0.37" stop-color="#9A30CE" />
          <stop offset="0.46" stop-color="#7E26BE" />
          <stop offset="0.55" stop-color="#651DB1" />
          <stop offset="0.63" stop-color="#5015A5" />
          <stop offset="0.75" stop-color="#390D98" />
          <stop offset="0.82" stop-color="#310B94" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default Vault;
