import { createIcon } from "@chakra-ui/icons";

const Phone = createIcon({
  displayName: "Phone",
  viewBox: "0 0 18 18",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.27 3.796s-.037.039-.084.085c-.181.177-.557.543-.56 1.31-.007 1.074.699 3.067 4.097 6.464 3.383 3.381 5.372 4.095 6.448 4.095h.016c.767-.004 1.133-.38 1.31-.56.054-.056.097-.097.128-.122.747-.752 1.13-1.311 1.126-1.67-.005-.366-.46-.798-1.09-1.397-.2-.19-.418-.397-.647-.627-.595-.593-.89-.492-1.538-.264-.896.314-2.126.742-4.037-1.17-1.914-1.913-1.486-3.141-1.172-4.037.226-.648.33-.944-.266-1.54-.233-.232-.442-.452-.635-.655-.595-.625-1.024-1.078-1.387-1.083h-.006c-.36 0-.918.384-1.707 1.174l.003-.003Zm9.902 13.08c-1.87 0-4.306-1.489-7.244-4.425C2.98 9.503 1.488 7.058 1.5 5.186c.007-1.238.656-1.872.9-2.11.012-.015.056-.058.07-.073C3.547 1.927 4.272 1.492 4.994 1.5c.838.011 1.434.638 2.188 1.433.187.196.39.41.615.635 1.095 1.095.783 1.988.533 2.705-.273.782-.51 1.457.905 2.872 1.416 1.415 2.091 1.178 2.87.903.718-.25 1.61-.564 2.706.531.222.222.432.422.627.607.798.759 1.428 1.358 1.438 2.198.01.716-.426 1.446-1.5 2.52l-.475-.322.402.393c-.238.244-.871.894-2.11.9h-.02Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".4"
      />
    </>
  ),
});

export default Phone;
